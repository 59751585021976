import React, {useEffect} from "react";
import "./FindYourShop.scss";
import {LocationOn} from "@material-ui/icons";
import {Icon} from "@material-ui/core";
import Footer from "../../components/Footer/Footer";
import {useTranslation} from "react-i18next";

export default function FindYourShop() {

    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="stores__container">
                <h1 className="stores__title">{t('stores.our-stores')}</h1>

                <div className="stores__info">
                    <h3>SWITCH PEOPLE - PALMA</h3>
                    <div className="stores__location">
                        <p><Icon className="MuiIcon-root icon stores__location"><LocationOn/></Icon></p>
                        <p className="stores__street-name">Avinguda de l'Argentina, 35 A</p>
                    </div>
                    <p className="stores__street-name">07013 Palma - Illes Balears - España</p>
                    <p className="stores__street-name">+34 971 73 77 76</p>
                    <a href="https://goo.gl/maps/Mq55xqbWg5dGfM9H9" target="_blank">{t('stores.open-google')}</a>
                </div>
                <div className="stores__info">
                    <h3>BUGARSKI SURFBOARDS - A CORUÑA</h3>
                    <div className="stores__location">
                        <p><Icon className="MuiIcon-root icon stores__location"><LocationOn/></Icon></p>
                        <p className="stores__street-name">Lugar de Xermaña, 3 - Caión</p>
                    </div>
                    <p className="stores__street-name">15145 Laracha - A Coruña - España</p>
                    <p className="stores__street-name">+34 657 90 02 25</p>
                    <a href="https://goo.gl/maps/XowX94d4eMhzTFwu6" target="_blank">{t('stores.open-google')}</a>
                </div>
                <div className="stores__info">
                    <h3>ITACARE SURF SHOP - MARTINIQUE</h3>
                    <div className="stores__location">
                        <p><Icon className="MuiIcon-root icon stores__location"><LocationOn/></Icon></p>
                        <p className="stores__street-name">Rue du Surf, 1</p>
                    </div>
                    <p className="stores__street-name">097220 - La Trinité - Martinique</p>
                    <p className="stores__street-name">+596 596 58 01 43</p>
                    <a href="https://goo.gl/maps/JkRmVqZp5PwmtGPh9" target="_blank">{t('stores.open-google')}</a>
                </div>
                <div className="stores__info">
                    <h3>MISTER GOOD SURF MGS - GUADELOUPE</h3>
                    <div className="stores__location">
                        <p><Icon className="MuiIcon-root icon stores__location"><LocationOn/></Icon></p>
                        <p className="stores__street-name">Centre commercial escale bas du fort Gosier</p>
                    </div>
                    <p className="stores__street-name">97190 - Le Gosier - Guadeloupe</p>
                    <p className="stores__street-name">+590 590 93 62 71</p>
                    <a href="https://goo.gl/maps/y4DzNkLWQepKN1fQ8" target="_blank">{t('stores.open-google')}</a>
                </div>
            </div>
            <Footer/>
        </div>
    );
};
