import React, {useState, useEffect, lazy, Suspense} from "react";
import { API } from "../../../shared/services/api";
import { Link } from "react-router-dom";
import "./FamilyPage.scss";
import {useTranslation} from "react-i18next";
import {FcPlus} from "react-icons/all";
import NewFamilyMember from "../../../components/NewFamilyMember/NewFamilyMember";
//import Footer from "../../../components/Footer/Footer";
const Footer = lazy(() => import('../../../components/Footer/Footer'));

export default function FamilyPage(props) {

    const [team, setTeam] = useState([]);
    const [t, i18n] = useTranslation("global");
    const [newForm, setNewForm] = useState(false);

    useEffect(() => {
        API.get('team/all').then(res => {
            setTeam(res.data);
        })
    }, [])

    return (
        <div className="team">
            <h2 className="team__title">{t('family.family')}</h2>

            {/*{props.isLogged &&*/}
            {/*<button onClick={() => setNewForm(true)} type="button" className="btn btn-secondary btn-lg mb-5">*/}
            {/*    <FcPlus/> ADD NEW MEMBER</button>*/}
            {/*}*/}
            {/*{ newForm &&*/}
            {/*<div>*/}
            {/*    <NewFamilyMember/>*/}
            {/*    <button className="btn btn-danger my-2" onClick={() => setNewForm(false)}> cancel</button>*/}
            {/*</div>*/}
            {/*}*/}

            <div className="col-12 row mx-0 team__container">
                {team.map((member, i) =>
                    <div className="team__surfer-container">
                        <Link team={team} to={'/playground/family/' + member.name + '/' + member.surname}>
                            <picture>
                                <source type="image/webp" srcSet={member.profilePicture}
                                        alt={member.name + ' ' + member.surname} className="team__photo"/>
                                <img className="team__photo" src={member.profilePictureJPG} alt={member.name + ' ' + member.surname}/>
                            </picture>
                        </Link>
                        <h4 className="team__name">{member.name} {member.surname}</h4>
                    </div>
                )}
            </div>
            <Suspense fallback={<p> </p>}>
                <Footer/>
            </Suspense>
        </div>
    )
}
