import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { API } from "../../shared/services/api";
import "./AdminPage.scss";

export default function LoginPage(props){

    const { register, handleSubmit, errors } = useForm();
    const history = useHistory();

    const onSubmit = formData => {
        API.post('user/login', formData).then(res => {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.user));
            props.setIsLogged(true);
            history.push('/felix/admin');
        })
    }
    return(
        <div className="login">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label className="login__label" htmlFor="email">E-mail</label>
                        <input type="text" name="email" ref={register ({required:true, pattern:/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })}/>
                        {errors.email && <span> Email incorrecto</span>}
                    </div>
                    <div>
                        <div>
                            <label className="login__label" htmlFor="password">Password</label>
                            <input type="password" name="password" ref={register ({required:true , minLength:8})}/>
                            {errors.password && <span> Contraseña incorrecta</span>}
                        </div>
                        <input className="login__button" type="submit" value="Iniciar sesión"/>
                    </div>
                    <a href="/">Go back to homepage without login</a>
                </form>
        </div>
    );
}
