import React, {useEffect, useState} from "react";
import {API} from "../../../../shared/services/api";
import {useParams} from "react-router-dom";
import "./DetailTeamPage.scss";
import {FiInstagram} from "react-icons/all";
import Footer from "../../../../components/Footer/Footer";
import {useTranslation} from "react-i18next";

export default function DetailTeamPage(props) {

    const name = useParams().name;
    const surname = useParams().surname;
    const [t, i18n] = useTranslation("global");

    const [memberDetail, setMemberDetail] = useState(null);
    const instaWeb = 'https://www.instagram.com/'

    const toMemberInsta = () => {
        window.open(instaWeb + memberDetail[0].instagram);
    }

    useEffect(() => {
        API.get('team/' + name + '/' + surname).then(res => {
            setMemberDetail(res.data)
        })
        console.log(name);
        console.log(surname);

    }, []);


    return (
        <div>
            {memberDetail &&
            <div className="team-detail">
                <div className="col-12 col-md-6 team-detail__container">
                    <img className="team-detail__riding" src={memberDetail[0].extraPicture} alt="Team member"/>
                    <div className="team-detail__insta-container">
                        <FiInstagram className="team-detail__icon" href={instaWeb + memberDetail[0].instagram}/>
                        <h5 onClick={toMemberInsta} className="team-detail__name">@{memberDetail[0].instagram}</h5>
                    </div>
                </div>
                <div className="col-12 col-md-5">
                    <h1>{memberDetail[0].name} {memberDetail[0].surname}</h1>
                    <div className="row px-3">
                        <p className="team-detail__fields">{t('family.age')}:</p>
                        <p className="team-detail__fields-data">{memberDetail[0].age}</p>
                    </div>
                    <div className="row px-3">
                        <p className="team-detail__fields">{t('family.hometown')}:</p>
                        <p className="team-detail__fields-data">{memberDetail[0].hometown}</p>
                    </div>
                    <div className="row px-3">
                        <p className="team-detail__fields">{t('family.position')}:</p>
                        <p className="team-detail__fields-data">{memberDetail[0].position}</p>
                    </div>
                    <div className="row px-3">
                        <p className="team-detail__fields">{t('family.hobbies')}:</p>
                        <p className="team-detail__fields-data">{memberDetail[0].hobbies}</p>
                    </div>
                    {memberDetail[0].championships[0] &&
                    <div>
                        <h4>{t('family.palmares')}</h4>
                        <ul>
                            {memberDetail[0].championships.[0] &&
                            <li><p className="team-detail__fields-data">{memberDetail[0].championships[0]}</p></li>
                            }
                            {memberDetail[0].championships.[1] &&
                            <li><p className="team-detail__fields-data">{memberDetail[0].championships[1]}</p></li>
                            }
                            {memberDetail[0].championships.[2] &&
                            <li><p className="team-detail__fields-data">{memberDetail[0].championships[2]}</p></li>
                            }
                            {memberDetail[0].championships.[3] &&
                            <li><p className="team-detail__fields-data">{memberDetail[0].championships[3]}</p></li>
                            }
                            {memberDetail[0].championships.[4] &&
                            <li><p className="team-detail__fields-data">{memberDetail[0].championships[4]}</p></li>
                            }
                        </ul>
                    </div>
                    }
                </div>
            </div>
            }
            <Footer/>
        </div>
    )
}
