import React, {useEffect, useState} from "react";
import {API} from "../../../shared/services/api";
import {FcPlus, VscArrowUp} from "react-icons/all";
import Footer from "../../../components/Footer/Footer";
import NewTribePics from "../../../components/NewTribePics/NewTribePics";

export default function TribePage(props) {

    const [allTribe, setTribe] = useState([]);

    const toTop = () => {
        window.scrollTo(0, 0);
    }

    const [newForm, setNewForm] = useState(false);

    useEffect(() => {
        API.get('tribe/all').then(res => {
            window.scrollTo(0, 0);
            setTribe(res.data[0].gallery.reverse());
        })
    }, []);

    return (
        <div className="team">
            <h2 className="team__title">VOODOO TRIBE</h2>
            {props.isLogged &&
            <button onClick={() => setNewForm(true)} type="button" className="btn btn-secondary btn-lg mb-5">
                <FcPlus/> ADD NEW PICTURES</button>
            }
            { newForm &&
            <div>
                <NewTribePics/>
                <button className="btn btn-danger my-2" onClick={() => setNewForm(false)}> cancel</button>
            </div>
            }


            <div className="container-fluid px-0 mx-0 team__tribe-container">
                <div className="d-flex flex-row flex-wrap px-0 mx-0">
                    {allTribe.map((tribe, i) =>
                        <img className="team__tribe-photo" src={tribe} alt="Voodoo Tribe Member"/>
                    )}
                </div>
            </div>
            <div className="team__beginning">
                <VscArrowUp onClick={toTop}/>
            </div>
            <Footer/>
        </div>
    )
}
