import React, {useEffect} from "react";
import Footer from "../Footer";

export default function ShippingInformationPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="legacy__container">
                <h2>SHIPPING POLICY</h2>
                <div className="legacy__paragraph">

                    <h3 className="legacy__title">SPAIN AND PORTUGAL</h3>
                    <p className="legacy__text">Shipments to <span className="voodoo">Spain</span> and <span className="voodoo">Portugal</span> are made through the post office.
                        Delivery times are <span className="voodoo">48-72 hours</span> of business days except in <span className="voodoo">Canary Islands, Ceuta and
                    Melilla</span> with are delivered in <span className="voodoo">5 to 8 business days.</span></p>
                    <p className="legacy__text"><span className="voodoo">Shipping cost</span></p>
                    <ul className="legacy__text">
                        <li className="legacy__text"><span className="voodoo">Free</span> for orders <span className="voodoo"> over 90€</span></li>
                        <li className="legacy__text"><span className="voodoo">6€</span> for orders <span className="voodoo">under 90€</span></li>
                        <li className="legacy__text"><span className="voodoo">6€</span> for all orders to the <span className="voodoo">Canary Islands, Ceuta y Melilla</span></li>
                    </ul>

                    <h3 className="legacy__title">EUROPE</h3>
                    <p className="legacy__text">Shipments to <span className="voodoo">Europe</span> are made through the post office or with a door-to-door agency
                        depending on the destination.</p>
                    <p className="legacy__text">Delivery times are <span className="voodoo">4 to 8 business days.</span></p>
                    <p className="legacy__text"><span className="voodoo">Shipping cost</span></p>
                    <ul className="legacy__text">
                        <li className="legacy__text"><span className="voodoo">Free</span> for orders <span className="voodoo"> over 120</span></li>
                        <li className="legacy__text"><span className="voodoo">6€</span> for orders <span className="voodoo">orders between 90€-120€</span></li>
                        <li className="legacy__text"><span className="voodoo">9€</span> for orders over <span className="voodoo">90€</span></li>
                    </ul>

                    <h3 className="legacy__title">CARIBBEAN</h3>
                    <p className="legacy__text">Shipments to the <span className="voodoo">Caribbean</span> are made through our distributor.
                        Consult us by sending an e-mail to <span className="voodoo legacy__underline-text">contact@thevoodoobrand.com</span> or by calling
                        <span className="voodoo">+596.696.89.10.48.</span></p>
                    <p className="legacy__text"><span className="voodoo">No shipments are made on Saturdays, Sundays and holidays.</span></p>
                    <p className="legacy__text"><span className="voodoo">Orders to Europe may experience delays out offer control due to Covid-19 safety regulations and Brexit protocols.</span></p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
