import React from "react";
import Slider from "react-slick";

export default function SliderTablet() {

    const welcome = 'Welcome to Voodoo'

    let settings = {
        dots: false,
        infinite: true,
        speed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        adaptiveHeight: true
    };

    return (
        <div>
            <div className="slider__container">
                <Slider {...settings}>

                    <picture id="1-tablet">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371677/webP/slider/tablet/Slider_1_ry7agq.webp" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420648/PNG_JPG/slider/tablet/Slider_1_jdsu27.png" atl={welcome}/>
                    </picture>

                    <picture id="2-tablet">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371677/webP/slider/tablet/Slider_2_drhtwy.png" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420647/PNG_JPG/slider/tablet/Slider_2_k2r4uy.png" atl={welcome}/>
                    </picture>

                    <picture id="3-tablet">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371678/webP/slider/tablet/Slider_3_rxinha.png" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420646/PNG_JPG/slider/tablet/Slider_3_n0ccfw.png" atl={welcome}/>
                    </picture>

                    <picture id="4-tablet">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371678/webP/slider/tablet/Slider_4_ibq0ur.webp" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420647/PNG_JPG/slider/tablet/Slider_4_c03dtz.png" atl={welcome}/>
                    </picture>

                    <picture id="5-tablet">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371678/webP/slider/tablet/Slider_5_vaanpo.png" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420646/PNG_JPG/slider/tablet/Slider_5_zdtnsw.png" atl={welcome}/>
                    </picture>

                    <picture id="6-tablet">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371677/webP/slider/tablet/Slider_6_etnblo.webp" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420646/PNG_JPG/slider/tablet/Slider_6_icaay3.png" atl={welcome}/>
                    </picture>

                </Slider>
            </div>
        </div>
    )
}
