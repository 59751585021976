import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {API} from "../../../shared/services/api";
import Footer from "../../../components/Footer/Footer";
import "./TshirtsPage.scss";
import {useTranslation} from "react-i18next";

export default function TshirtPage() {

    const [tshirts, setTshirts] = useState([]);
    const width = window.innerWidth;
    const [t, i18n] = useTranslation("global");

    const [hovered, setHovered] = useState(false);

    const hoveredImg = () => {
        setHovered(true);
    }

    const unhoveredImg = () => {
        setHovered(false);
    }

    useEffect(() => {
            API.get('product/tshirts').then(res =>
                setTshirts(res.data)
            )
        },
        [])

    return (
        <div className="product__img__gallery-container">
            <p className="product__img__black-text center">{t('products.t-shirts-col')}</p>
            <div className="col-12 row px-0 mx-0 product__img__big-container">

                {/* tshirts loop */}

                {tshirts.map((tshirt, i) =>
                    <div key={tshirt._id} className="col-12 col-md-6 col-xl-3 product__img__container">
                        {width < 550 &&
                        <Link to={"/wardrobe/" + tshirt.title + "/" + tshirt.color} className="product__img__link">

                            <picture>
                                <source type="image/webp" srcSet={tshirt.frontPicture.phone}  className="product__img__gallery-section" alt={tshirt.title}/>
                                <img  className="product__img__gallery-section" src={tshirt.frontPictureJPG.phone} alt={tshirt.title}/>
                            </picture>

                        </Link>
                        }
                        {width > 550 &&
                        <Link to={"/wardrobe/" + tshirt.title + "/" + tshirt.color} className="product__img__link">

                            <picture>
                                <source type="image/webp" srcSet={tshirt.frontPicture.tablet}  className="product__img__gallery-section" alt={tshirt.title}/>
                                <img  className="product__img__gallery-section" src={tshirt.frontPictureJPG.tablet} alt={tshirt.title}/>
                            </picture>

                        </Link>

                        }

                        <p className="product__img__black-text custom-typo">{tshirt.title}</p>
                        <p className="product__img__price">{tshirt.price} €</p>
                        <div className="align-self-center my-2">
                            <div className={tshirt.color}></div>
                            {
                                width < 992 &&
                                    <div className="product__img__space">

                                    </div>
                            }
                        </div>
                    </div>
                )}
            </div>
            <Footer/>
        </div>

    )
}
