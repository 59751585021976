import React, {useEffect, useState} from "react";
import {FiInstagram} from "react-icons/all";
import {GrFacebook} from "react-icons/all";
import {FaSpotify} from "react-icons/all";
import {FiPrinter} from "react-icons/all";
import "./CorrectPaymentPage.scss";
import {API, BACK_URL, PATH} from "../../shared/services/api";
import axios from "axios";

export default function CorrectPaymentPage() {

    const [boughtProducts, setBoughtProducts] = useState([]);
    const [cart, setCart] = useState([]);
    const [customer, setCustomer] = useState({});
    const [paid, setPaid] = useState(0);
    const [numberToShow, setNumberToShow] = useState(null)

    useEffect(() => {

            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = today.getFullYear();

            let month;

            if (mm < 10) {
                month = parseInt(mm, 10)
            } else {
                month = mm
            }

            today = mm + '/' + dd + '/' + yyyy;

            API.get('order/' + yyyy + '/' + month).then(res => {
                if (res.data[res.data.length - 1] == undefined) {
                    setNumberToShow(yyyy + mm + '-' + 0 + 0 + 1);
                } else {
                    let lastOrder = res.data[res.data.length - 1].purchaseCode;
                    let digitsLastOrder = lastOrder.substr(lastOrder.length - 3)
                    let parseDigit = parseInt(digitsLastOrder);
                    let newDigit = parseDigit + 1;

                    if (newDigit < 10) {
                        setNumberToShow(yyyy + mm + '-' + 0 + 0 + newDigit)
                    } else if (newDigit >= 10 && newDigit <= 99) {
                        setNumberToShow(yyyy + mm + '-' + 0 + newDigit)
                    } else {
                        setNumberToShow(yyyy + mm + '-' + newDigit)
                    }
                }
            })

            let localCustomer = JSON.parse(localStorage.getItem('customer'));
            setCustomer(localCustomer)

            let localCart = JSON.parse(localStorage.getItem('cart'));
            setCart(localCart);
            setBoughtProducts(localCart)

            let localPaid = JSON.parse(localStorage.getItem('paid'));
            setPaid(localPaid)

            submitOrder(yyyy, mm, dd);

    }, [numberToShow])


    const removeCart = () => {
        localStorage.removeItem('cart');
    }


    const submitOrder = (year, month, day) => {

        let address;
        if (customer.address_extra) {
            address = customer.street.concat(', ', customer.address_extra)
        } else {
            address = customer.street
        }

        let items = [];

        for (let i = 0; i < boughtProducts.length; i++) {
            let bought = {};
            bought['_id'] = boughtProducts[i].id;
            bought['price'] = boughtProducts[i].price;
            bought['quantity'] = boughtProducts[i].quantity;
            bought['size'] = boughtProducts[i].size || boughtProducts[i].surfSize;
            bought['title'] = boughtProducts[i].title;
            bought['type'] = boughtProducts[i].type;
            bought['color'] = boughtProducts[i].color ? boughtProducts[i].color : '';

            items.push(bought)
        }

        const order = {
            name: customer.name,
            surname: customer.surname,
            purchaseCode: numberToShow,
            totalAmount: paid.totalAmount,
            subtotal: paid.subtotal,
            shipping: paid.shipping,
            address: address,
            postalCode: customer.postalCode,
            city: customer.city,
            country: customer.country,
            purchaseYear: year,
            purchaseMonth: parseInt(month),
            purchaseDay: parseInt(day),
            email: customer.email,
            phone: customer.phone,
            products: items
        }


        if (order.purchaseCode !== null) {
            API.post('/order/submit', order)
                .then(res => {
                    console.log(res.data)
                    removeCart();
                    API.post('/order/send', order)
                        .then(res => {
                        }
                )
                        .catch(error =>
                            console.log('')
                        )
                }
        )
                .catch(error =>
                    console.log('')
                )
        }
    }

    const instagram = () => {
        window.open('https://www.instagram.com/thevoodoobrand/')
    }

    const facebook = () => {
        window.open('https://www.facebook.com/Thevoodoobrand-107916997551187')
    }

    const spotify = () => {
        window.open('https://open.spotify.com/playlist/4npvjdLbbkbN6DBQcHbfDx?si=OjR_1_JPTfKVrwwCJ6MXbQ')
    }

    return (
        <div className="succeded__container">
            <h2>Hello, {customer.name} {customer.surname}</h2>
            <FiPrinter className="succeded__print" onClick={() => window.print()}/>
            <p className="succeded__congratulations">Congratulations on joining the tribe and being a part of this
                movement. We want to
                link cultures through people.</p>
            <p><span className="montserrat">Your order number is: </span>
                <h3>{numberToShow}</h3>
            </p>

            <p className="succeded__congratulations">We will soon start preparing your order and we will let you know
                when it ships.</p>
            <div className="row col-12 justify-content-center">
                <div className="col-12 col-lg-5">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col"><h3 className="succeded__shipping-title">Shipping information</h3></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">
                                <span className="montserrat">{customer.name} {customer.surname}</span>
                            </th>
                        </tr>
                        <tr>
                            <th scope="row">
                        <span
                            className="montserrat">{customer.street} {customer.address_extra && '-' + customer.address_extra}</span>
                            </th>
                        </tr>
                        <tr>
                            <th scope="row">
                                <span
                                    className="montserrat">{customer.city} - {customer.postalCode} - {customer.country}</span>
                            </th>
                        </tr>
                        <tr>
                            <th scope="row">
                                <span className="montserrat">{customer.email}</span>
                            </th>
                        </tr>
                        <tr>
                            <th scope="row">
                                <span className="montserrat">{customer.phone}</span>
                            </th>
                        </tr>
                        </tbody>

                    </table>
                </div>

                {cart &&
                <div className="col-12 col-lg-5">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col"><h3 className="succeded__shipping-title">Purchased products</h3></th>
                        </tr>
                        </thead>
                        <tbody>
                        {cart.map((item, i) =>
                            <tr>
                                <th scope="row">
                                    <span className="montserrat"><span
                                        className="font-weight-bold">{item.title} {item.type} x {item.quantity}</span> - {item.size && item.size} {item.surfSize && item.surfSize}</span>
                                </th>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                }
            </div>
            <div className="separator"></div>
            <p className="succeded__message">¡Thank you very much for trusting us!</p>
            <p className="succeded__congratulations">If you have any more concerns, contact us by e-mail</p>
            <p className="succeded__email">contact@thevoodoobrand.com</p>

            <div className="succeded__social-media">
                <p>Follow us on:</p>
                <p className="succeded__social-media__icons">
                    <FiInstagram onClick={instagram} className="succeded__social-media__icons__each"/>
                    <GrFacebook onClick={facebook} className="succeded__social-media__icons__each"/>
                    <FaSpotify onClick={spotify} className="succeded__social-media__icons__each"/>
                </p>
            </div>


        </div>
    )
}
