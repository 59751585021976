import React from "react"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomePage.scss";
import {Link} from "react-scroll";
import {Suspense, lazy} from "react";
import SliderPhone from "../../components/Slider/SliderPhone";
import SliderTablet from "../../components/Slider/SliderTablet";
import SliderWeb from "../../components/Slider/SliderWeb";
const Categories = lazy(() => import('../../components/Categories/Categories'));
const Footer = lazy(() => import('../../components/Footer/Footer'));

export default function HomePage() {

    const width = window.innerWidth;

    return (
        <div>
            <div className="slider__container">
                {width < 700 &&
                    <SliderPhone/>
                }
                {width > 700 && width < 1200 &&
                    <SliderTablet/>
                }
                {width > 1200 &&
                    <SliderWeb/>
                }
                <Link to="categories" spy={true} smooth={true} offset={0} duration={500}>
                    <div className="arrow-slider-container no-arrow"></div>
                </Link>
                <Suspense fallback={<p>WARDROBE - PLAYGROUND - QUIVER</p>}>
                    <Categories id="categories"/>
                </Suspense>
                <Suspense fallback={<p>The Voodoo Brand...</p>}>
                    <Footer/>
                </Suspense>
            </div>
        </div>
    )
}
