import React from "react";
import Slider from "react-slick";

export default function SliderPhone() {

    const welcome = "Welcome to Voodoo";

    let settings = {
        dots: false,
        infinite: true,
        speed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        adaptiveHeight: true
    };

    return (
        <div>
            <div className="slider__container">
                <Slider {...settings}>

                    <picture id="1-phone">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371669/webP/slider/phone/Slider_1__zpnvqc.webp" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420603/PNG_JPG/slider/phone/Slider_1_kvkwqt.png" atl={welcome}/>
                    </picture>

                    <picture id="2-phone">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371670/webP/slider/phone/Slider_2__jszanq.png" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627377147/PNG_JPG/slider/phone/Slider_2__ilepd3.png" atl={welcome}/>
                    </picture>

                    <picture id="3-phone">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371670/webP/slider/phone/Slider_3__sdks8k.png" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420603/PNG_JPG/slider/phone/Slider_3_ztm9hb.png" atl={welcome}/>
                    </picture>

                    <picture id="4-phone">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371669/webP/slider/phone/Slider_4__nno5ru.webp" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420602/PNG_JPG/slider/phone/Slider_4_yzegiu.png" atl={welcome}/>
                    </picture>

                    <picture id="5-phone">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371670/webP/slider/phone/Slider_5__cjwuw8.png" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420603/PNG_JPG/slider/phone/Slider_5_wkla8x.png" atl={welcome}/>
                    </picture>

                    <picture id="6-phone">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371669/webP/slider/phone/Slider_6__aqcnpi.webp" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420602/PNG_JPG/slider/phone/Slider_6_loro6u.png" atl={welcome}/>
                    </picture>

                </Slider>
            </div>
        </div>
    )
}
