import React, {useEffect} from "react";
import "./AboutUsPage.scss";
import "../../components/Footer/Footer.scss";
import Footer from "../../components/Footer/Footer";

export default function AboutUsPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="bgcontainer flex-column justify-content-center text-justify">
                <h1>Voodoo</h1>
                <h3>Origin and meaning</h3>
                <p className="montserrat justify-content-center col-lg-6 col-md-8 col-sm-10 col-xs-12"><span className="voodoo">Voodoo</span> was born in the 17th century when many Africans from the Gulf of Guinea were taken as slaves to Haiti, the French Antilles and several other islands in the Caribbean. Those slaves created the <span className="voodoo">Voodoo</span>, at that time this practice was considered as a religion aimed to communicate with ancestors in the form of spirits to guide them on their spiritual and vital paths.</p>
                <p className="montserrat justify-content-center col-lg-6 col-md-8 col-sm-10 col-xs-12"><span className="voodoo">Voodoo</span> means protective spirit or genius which was invoked when the slaves were brutally mistreated as a symbol of rebellion against their masters and in search of their freedom. Those spirits ruled all the forces of nature: the sun, the sea, the fire, the moon, the earth, the trees, the rocks, the rivers, as well as the places, the crossroads, the clans and the tribes.</p>
                <p className="montserrat justify-content-center col-lg-6 col-md-8 col-sm-10 col-xs-12">Therefore, very far from reality, nowadays we have a very distorted image of <span className="voodoo">Voodoo</span>. The masters, politicians and colonialists considered it a threat. This idea hit the big screen and it generated a misconception in society and it was associated to black and diabolical magic.</p>
                <h3>THE BRAND</h3>
                <p className="montserrat justify-content-center col-lg-6 col-md-8 col-sm-10 col-xs-12"><span className="voodoo">Voodoo The Brand</span> was born in the Caribbean, specifically in the French West Indies in the year 2020 at a time of changes, seeking rebellion against the current system. A diseased system of which we are slaves. We want to break free and run away from laws and stereotypes, find ourselves with the natural essence and go back to the basics.</p>
                <p className="montserrat justify-content-center col-lg-6 col-md-8 col-sm-10 col-xs-12">Exhausted of always hearing the same ideas, we want <span className="voodoo">The Voodoo Brand</span> to transmit an image of rebellion with elegance, fresh and natural (be a gentleman with a punk heart) which may be always with you.</p>
                <p className="montserrat justify-content-center col-lg-6 col-md-8 col-sm-10 col-xs-12">The brand seeks the maximum comfort, to give you the maximum comfort both in your daily life as in your sports sessions through natural materials.</p>
                <h4 className="col-12 text-center">DON'T DO MAGIC. DO VOOODOO. JOIN THE TRIBE</h4>
            </div>
            <Footer/>
        </div>
    )
}
