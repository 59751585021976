import React, {useEffect} from "react";
import "./ContactingPage.scss";
import Footer from "../../components/Footer/Footer";
import {API} from "../../shared/services/api";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

export default function ContactingPage() {

    const {register, handleSubmit, errors, reset} = useForm();
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onSubmit = (formData) => {
        console.log(formData)
        // API.post('/mail/contact', formData)
        //     .then(res => {
        //         console.log(res);
        //     }).catch(err => console.log(err))
        // reset();
    }

    return (
        <div>
            <div className="form__big-container">
            </div>
            <div className="centered form">
                <div className="shadow-lg row col-10 col-sm-8 col-xl-5 px-0">
                    <div className="col-lg-12 form__container px-4 px-sm-5">

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <label className="contact-text">{t('form.name')}</label>
                                <input id="name" type="text" className="form-control input contact" name="name"
                                       ref={register({required: true, pattern: /^[-'a-zA-ZÀ-ÖØ-öø-ÿ_ ]+$/})}/>
                                {errors.name &&
                                <span className="customer-form__warning"> Introduce a valid name </span>}

                            </div>
                            <div>
                                <label className="contact-text">{t('form.surname')}</label>
                                <input id="surname" type="text" className="form-control input contact" name="surname"
                                       ref={register({required: true, pattern: /^[-'a-zA-ZÀ-ÖØ-öø-ÿ_ ]+$/})}/>
                                {errors.surname &&
                                <span className="customer-form__warning"> Introduce a valid surname </span>}

                            </div>
                            <div>
                                <label className="contact-text">E-mail*</label>
                                <input id="email" type="text" className="form-control input contact" name="email"
                                       ref={register({
                                           required: true,
                                           pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                                       })}/>
                                {errors.email && <span className="customer-form__warning"> Review e-mail field</span>}
                            </div>
                            <div>
                                <label className="contact-text">{t('form.subject')}*</label>
                                <input id="subject" type="text" className="form-control input contact" name="subject"
                                       ref={register({required: true, pattern: /^[-'a-zA-ZÀ-ÖØ-öø-ÿ_ ]+$/})}/>
                                {errors.subject &&
                                <span className="customer-form__warning"> Introduce a valid subject </span>}
                            </div>
                            <div>
                                <label className="contact-text">{t('form.message')}*</label>
                                <div>
                                    <textarea id="message" name="message" rows="5" className="col-12 message"
                                              ref={register({required: true})}></textarea>
                                </div>
                            </div>
                            <div className="centered">
                                <input className="btn-form" type="submit" value={t('form.send')}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer className="form__footer"/>
        </div>

    );
}
