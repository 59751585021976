import React from 'react';
import {useForm} from "react-hook-form";
import {APIimage} from "../../shared/services/api";

export default function NewTribePics() {

    const {handleSubmit} = useForm();

    const onSubmit = () => {
        let formData = new FormData();
        let imageFile = document.querySelector('#file');
        console.log(formData.append('image', imageFile.files[0]))

        APIimage.post('tribe/add', formData).then((res => {
                window.alert('Para ver los cambios, actualiza la página')
            })
        )
    }

    return (
        <div className="customer-form">
            <form id="customer-info-form" className="col-12" onSubmit={handleSubmit(onSubmit)}>
                <p className="badge badge-pill badge-primary montserrat">ADDING NEW PICTURE</p>

                <input id="file" type="file" name="image" className="my-5 align-self-center"/>

                <input className="btn btn-primary btn-lg btn-block my-4" type="submit" value="CONFIRM"/>
            </form>
        </div>
    )
}
