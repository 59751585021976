import React, {useContext, useEffect, useState} from "react";
import {Navbar, Nav, NavDropdown, Form, FormControl, NavItem} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import blackLogo from "./assets/LogoEsloganBlack.png";
import {FiShoppingCart} from "react-icons/all";
import {GrClose} from "react-icons/all";
import {AiOutlineMenu} from "react-icons/all";
import {VscSearch} from "react-icons/all";
import "./Navbar.scss";
import {CartContext} from "../Context/CartContext";
import Modal from "react-modal";
import {BACK_URL, PATH} from "../../shared/services/api";
import CartComponent from "../../pages/CartPage/CartComponent";
//stripe official
import { loadStripe } from '@stripe/stripe-js';
import Search from "../Search/Search";
const stripePromise = loadStripe('pk_live_51INLKqGIRiUvGwl0P4b16xIZWbk9uXbJsLIbyExpHVTgsUylNSZKVQoe3qBmbdm8LDS5flrk6cHhv6Rd2fxI03Z700I6yhH1B9');

export default function NavigationBar() {

    const [t, i18n] = useTranslation("global");
    const [token, setToken] = useState('');

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setToken(localStorage.getItem('token'));
        }
    })

    // previous stripe method
    const handleCheckout = async (event) => {
        event.preventDefault();
        const stripe = await stripePromise;
        console.log(BACK_URL)
        const response = await fetch(BACK_URL + 'stripe/create-checkout-session', {
            method: 'POST',
            header: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer' + localStorage.getItem("token")
            }
        });
        const session = await response.json();
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
    }

        const width = window.innerWidth;
        const [navExpanded, setNavExpanded] = useState(false);

        const {countCart} = useContext(CartContext);
        let [totalPrice, setTotalPrice] = useState([]);
        const [modalIsOpen, setModalIsOpen] = useState(false);
        const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);

        function handleCloseModal() {
            setModalIsOpen(false);
        }

        function handleOpenModal() {
            setSearchModalIsOpen(false);
            setModalIsOpen(true);
            setNavExpanded(false);
        }

        function handleOpenSearchModal() {
            setModalIsOpen(false);
            setSearchModalIsOpen(true);
        }

        function handleCloseSearchModal() {
            setSearchModalIsOpen(false);
        }

        const toPayment = () => {
            window.location.assign(PATH.PAYMENT)
        }

        const toHome = () => {
            window.location.assign(PATH.HOME)
        }

        //the following function finds elements that overflow-x

        // var docWidth = document.documentElement.offsetWidth;
        //
        // [].forEach.call(
        //     document.querySelectorAll('*'),
        //     function(el) {
        //         if (el.offsetWidth > docWidth) {
        //             console.log(el);
        //         }
        //     }
        // );

        return (
            <div className="navbar">
                <Navbar expand="lg">
                    {/*<Nav.Link href="/">*/}
                    <img className="navbar__logo" src={blackLogo} alt="The Voodoo Brand Logo" onClick={toHome}/>
                    {/*</Nav.Link>*/}
                    <Navbar.Toggle id="toggler" onClick={() => setNavExpanded(true)} expanded={navExpanded}
                                   aria-controls="basic-navbar-nav">
                        <AiOutlineMenu className="navbar__custom-icon"/>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                        </Nav>
                        <Nav>
                            {token &&
                                <Nav.Link className="navbar__typo admin" href="/felix/admin">ADMIN</Nav.Link>
                            }
                            {token &&
                            <Nav.Link className="navbar__typo admin" href="/felix/stock">STOCK</Nav.Link>
                            }
                            <Nav.Link className="navbar__typo" href="/">{t('navbar.home')}</Nav.Link>
                            <Nav.Link className="navbar__typo" href={PATH.SURFBOARDS}>{t('navbar.quiver')}</Nav.Link>
                            <NavDropdown className="navbar__typo" title={t('navbar.wardrobe')} id="basic-nav-dropdown">
                                <NavDropdown.Item href="/wardrobe/tshirts">{t('navbar.t-shirts')}</NavDropdown.Item>
                                <NavDropdown.Item href="/wardrobe/sweatwear">{t('navbar.hoodies-sweatshirts')}</NavDropdown.Item>
                                <NavDropdown.Item href="/wardrobe/headwear">{t('navbar.caps-beanies')}</NavDropdown.Item>
                                {/*<NavDropdown.Item href="/wardrobe/wetsuits">Wetsuits</NavDropdown.Item>*/}
                            </NavDropdown>
                            <NavDropdown className="navbar__typo" title={t('navbar.playground')} id="basic-nav2-dropdown">
                                <NavDropdown.Item href="/playground/family">{t('navbar.family')}</NavDropdown.Item>
                                <NavDropdown.Item href="/playground/tribe">{t('navbar.tribe')}</NavDropdown.Item>
                            </NavDropdown>
                            {width < 992 &&
                            <Navbar.Toggle className="toggle">
                                <p className="navbar__cart-responsive" onClick={handleOpenModal}>{t('navbar.cart')}<FiShoppingCart
                                    className="navbar__icon"/>({countCart})</p>
                            </Navbar.Toggle>
                            }
                            {width > 991 &&
                            <div className="row mx-2 align-items-center">
                                <NavItem>
                                    <p onClick={handleOpenModal} className="navbar__typo cart__link">{t('navbar.cart')}<FiShoppingCart
                                        className="navbar__icon"/>({countCart})</p>
                                </NavItem>
                                <span><VscSearch className="navbar__search" onClick={handleOpenSearchModal}/></span>

                                {/* search input for products ------ modal */}
                                <Modal className="navbar__search-modal" isOpen={searchModalIsOpen}
                                       onRequestClose={handleCloseModal}>
                                    <div className="sidebar__close-container">
                                        <button className="sidebar__close" onClick={() => setSearchModalIsOpen(false)}>
                                            <GrClose/></button>
                                    </div>
                                    <Search/>
                                </Modal>
                            </div>
                            }
                        </Nav>
                        {/* modal to open full screen size main pictures */}

                        <Modal className="sidebar" isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
                            <div className="sidebar__close-container">
                                <button className="sidebar__close" onClick={() => setModalIsOpen(false)}><GrClose/>
                                </button>
                            </div>
                            <CartComponent toChild={totalPrice} sendTotal={setTotalPrice}/>
                            <div className="sidebar__checkout-container">
                                <h4 className="sidebar__total-info">Total {totalPrice} €</h4>
                                <div className="sidebar__checkout-container__both">
                                    {totalPrice > 0 &&
                                    <button role="link"
                                            className="sidebar__checkout-button" onClick={toPayment}>{t('navbar.checkout')}
                                    </button>
                                    }
                                </div>
                                <button className="sidebar__continue-button"
                                        onClick={() => setModalIsOpen(false)}>{t('navbar.continue-shopping')}
                                </button>
                            </div>
                        </Modal>

                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }

