import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

export default function NewProduct() {

    const {register, handleSubmit, errors} = useForm();

    const onSubmit = formData => {
        console.log(formData)
    }

    return (
        <div className="customer-form">
            <form id="customer-info-form" className="col-12" onSubmit={handleSubmit(onSubmit)}>
                <p className="badge badge-pill badge-primary montserrat">ADDING NEW PRODUCT</p>

                <div className="row mx-0 align-items-start">
                    <div className="customer-form__input__half-container">

                        <label className="" htmlFor="email">TITLE/NAME OF THE PRODUCT</label>
                        <input className="customer-form__input" type="text" name="title"
                               placeholder="Roxane, Violet, Eleanor..."
                               ref={register({required: true, pattern: /^([+\d].*)?\d$/})}/>
                        {errors.email && <span className="customer-form__warning"> Review the title</span>}

                    </div>
                    <div className="customer-form__input__half-container">
                        <label className="" htmlFor="name">TYPE OF PRODUCT</label>
                        <select>
                            <option value="T-shirt">T-shirt</option>
                            <option value="Cap">Cap</option>
                            <option value="Beanie">Beanie</option>
                            <option value="Sweatshirt">Sweatshirt</option>
                            <option value="Hoodie">Hoodie</option>
                            <option value="Surfboard">Surfboard</option>
                        </select>
                    </div>
                </div>

                <div className="row mx-0 align-items-start">
                    <div className="customer-form__input__half-container">
                        <label className="" htmlFor="gender">Gender</label>
                        <select>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Unisex">Unisex</option>
                        </select>
                    </div>
                    <div className="customer-form__input__half-container">
                        <label className="" htmlFor="surname">Color</label>
                        <select>
                            <option value="Gold">Gold</option>
                            <option value="Yellow">Yellow</option>
                            <option value="Grey">Grey</option>
                            <option value="Black">Black</option>
                            <option value="Khaki">Khaki</option>
                            <option value="White">White</option>
                            <option value="OffWhite">Off White</option>
                            <option value="Red">Red</option>
                            <option value="Green">Green</option>
                            <option value="Blue">Blue</option>
                        </select>
                    </div>
                </div>
                <div className="row mx-0">

                    <div className="customer-form__input__half-container">
                        <label className="" htmlFor="price">PRICE OF THE PRODUCT</label>
                        <input className="customer-form__input" type="number" name="price"
                               placeholder="Número redondo (35, 1240, 49...)"
                               ref={register({required: true})}/>
                    </div>
                    <div className="customer-form__input__half-container">
                        <label className="" htmlFor="productCode">CÓDIGO DE PRODUCTO</label>
                        <input className="customer-form__input" type="text" name="productCode"
                               placeholder="Tiene que ser único: ca5, go11..."
                               ref={register({required: true})}/>
                    </div>

                </div>

                <h5 className="mt-5">URL Foto <span className="text-danger">PRINCIPAL</span> formato <span className="alert-primary">PNG/JPG/JPEG</span></h5>
                <div className="row mx-0">
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="frontPicturePhone"><span
                            className="badge badge-pill badge-dark montserrat">PHONE</span></label>
                        <input className="customer-form__input" type="text" name="frontPicturePhoneJPG"
                               placeholder="http://www.cloudinary....phone.com"
                               ref={register({required: true})}/>
                    </div>
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="productCode"><span
                            className="badge badge-pill badge-info montserrat">TABLET</span></label>
                        <input className="customer-form__input" type="text" name="frontPictureTabletJPG"
                               placeholder="http://www.cloudinary....tablet.com"
                               ref={register({required: true})}/>
                    </div>
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="frontPicturePhone"><span
                            className="badge badge-pill badge-warning montserrat">WEB</span></label>
                        <input className="customer-form__input" type="text" name="frontPictureWebJPG"
                               placeholder="http://www.cloudinary....web.com"
                               ref={register({required: true})}/>
                    </div>
                </div>

                <h5 className="mt-5">URL Foto <span className="text-danger">PRINCIPAL</span> formato <span className="alert-primary">WEBP</span></h5>
                <div className="row mx-0">
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="frontPicturePhone"><span
                            className="badge badge-pill badge-dark montserrat">PHONE</span></label>
                        <input className="customer-form__input" type="text" name="frontPicturePhoneJPG"
                               placeholder="http://www.cloudinary....phone.com"
                               ref={register({required: true})}/>
                    </div>
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="productCode"><span
                            className="badge badge-pill badge-info montserrat">TABLET</span></label>
                        <input className="customer-form__input" type="text" name="frontPictureTabletJPG"
                               placeholder="http://www.cloudinary....tablet.com"
                               ref={register({required: true})}/>
                    </div>
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="frontPicturePhone"><span
                            className="badge badge-pill badge-warning montserrat">WEB</span></label>
                        <input className="customer-form__input" type="text" name="frontPictureWebJPG"
                               placeholder="http://www.cloudinary....web.com"
                               ref={register({required: true})}/>
                    </div>
                </div>

                <h5 className="mt-5">URL Foto <span className="text-danger">SECUNDARIA</span> formato <span className="alert-primary">PNG/JPG/JPEG</span></h5>
                <div className="row mx-0">
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="frontPicturePhone"><span
                            className="badge badge-pill badge-dark montserrat">PHONE</span></label>
                        <input className="customer-form__input" type="text" name="frontPicturePhone"
                               placeholder="http://www.cloudinary....phone.com"
                               ref={register({required: true})}/>
                    </div>
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="productCode"><span
                            className="badge badge-pill badge-info montserrat">TABLET</span></label>
                        <input className="customer-form__input" type="text" name="frontPictureTablet"
                               placeholder="http://www.cloudinary....tablet.com"
                               ref={register({required: true})}/>
                    </div>
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="frontPicturePhone"><span
                            className="badge badge-pill badge-warning montserrat">WEB</span></label>
                        <input className="customer-form__input" type="text" name="frontPictureWeb"
                               placeholder="http://www.cloudinary....web.com"
                               ref={register({required: true})}/>
                    </div>
                </div>

                <h5 className="mt-5">URL Foto <span className="text-danger">SECUNDARIA</span> formato <span className="alert-primary">WEBP</span></h5>
                <div className="row mx-0">
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="frontPicturePhone"><span
                            className="badge badge-pill badge-dark montserrat">PHONE</span></label>
                        <input className="customer-form__input" type="text" name="frontPicturePhone"
                               placeholder="http://www.cloudinary....phone.com"
                               ref={register({required: true})}/>
                    </div>
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="productCode"><span
                            className="badge badge-pill badge-info montserrat">TABLET</span></label>
                        <input className="customer-form__input" type="text" name="frontPictureTablet"
                               placeholder="http://www.cloudinary....tablet.com"
                               ref={register({required: true})}/>
                    </div>
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="frontPicturePhone"><span
                            className="badge badge-pill badge-warning montserrat">WEB</span></label>
                        <input className="customer-form__input" type="text" name="frontPictureWeb"
                               placeholder="http://www.cloudinary....web.com"
                               ref={register({required: true})}/>
                    </div>
                </div>

                <h5 className="mt-5">URL Foto <span className="text-danger">EXTRAS</span> formato <span className="alert-primary">JPG/PNG/JPEG</span></h5>
                <div className="row mx-0">
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="frontPicturePhone"><span
                            className="badge badge-pill badge-info montserrat">TABLET</span></label>
                        <input className="customer-form__input" type="text" name="frontPicturePhone"
                               placeholder="http://www.cloudinary....tablet.com"
                               ref={register({required: true})}/>
                    </div>
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="productCode"><span
                            className="badge badge-pill badge-info montserrat">TABLET</span></label>
                        <input className="customer-form__input" type="text" name="frontPictureTablet"
                               placeholder="http://www.cloudinary....tablet.com"
                               ref={register({required: true})}/>
                    </div>
                    <div className="customer-form__input__third-container">
                        <label className="" htmlFor="frontPicturePhone"><span
                            className="badge badge-pill badge-info montserrat">TABLET</span></label>
                        <input className="customer-form__input" type="text" name="frontPictureWeb"
                               placeholder="http://www.cloudinary....tablet.com"
                               ref={register({required: true})}/>
                    </div>
                </div>

                <div className="input-group mt-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">DESCRIPCIÓN DEL PRODUCTO</span>
                    </div>
                    <textarea className="form-control montserrat" aria-label="With textarea" placeholder="Our Sharon T-shirt is..."></textarea>
                </div>

                <div className="input-group mt-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">CARE INFO</span>
                    </div>
                    <textarea className="form-control montserrat" aria-label="With textarea" placeholder="For a longer..."></textarea>
                </div>

                <div className="input-group mt-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">COMPOSITION</span>
                        <div className="input-group-prepend">
                            <span className="input-group-text">PERCENTAGE</span>
                        </div>
                    </div>
                    <textarea className="form-control montserrat" aria-label="With textarea"></textarea>
                    <div className="input-group-prepend">
                        <span className="input-group-text">COMPOSITION</span>
                        <div className="input-group-prepend">
                            <span className="input-group-text">GRAMS</span>
                        </div>
                    </div>
                    <textarea className="form-control montserrat" aria-label="With textarea" ></textarea>
                </div>


                <input className="btn btn-primary btn-lg btn-block my-4" type="submit" value="CONFIRMAR"/>
            </form>
        </div>
    )
}
