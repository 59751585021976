import React, {useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {PATH} from "./shared/services/api";
import HomePage from "./pages/HomePage/HomePage";
import WardrobePage from "./pages/ProductsPage/WardrobePage";
import SocksPage from "./pages/ProductsPage/SocksPage/SocksPage";
import TshirtsPage from "./pages/ProductsPage/TshirtsPage/TshirtsPage";
import UnderwearPage from "./pages/ProductsPage/UnderwearPage/UnderwearPage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import PlaygroundPage from "./pages/PlaygroundPage/PlaygroundPage";
import FindYourShop from "./pages/FindYourShop/FindYourShop";
import HeadwearPage from "./pages/ProductsPage/HeadwearPage/HeadWear";
import ContactingPage from "./pages/ContactPage/ContactingPage";
import SweatWear from "./pages/ProductsPage/SweatWearPage/SweatWear";
import DetailProductPage from "./pages/DetailProductPage/DetailProductPage";
import CartComponent from "./pages/CartPage/CartComponent";
import AdminPage from "./pages/AdminPage/AdminPage";
import PrivateRoute from "./shared/components/PrivateRoute/PrivateRoute";
import LoginPage from "./pages/AdminPage/LoginPage";
import FamilyPage from "./pages/PlaygroundPage/TeamPage/FamilyPage";
import DetailTeamPage from "./pages/PlaygroundPage/TeamPage/DetailTeamPage/DetailTeamPage";
import WetsuitsPage from "./pages/ProductsPage/WetsuitsPage/WetsuitsPage";
import ReturnsPolicyPage from "./components/Footer/Legacy/ReturnsPolicyPage";
import ShippingInformationPage from "./components/Footer/Legacy/ShippingInformationPage";
import TermsPage from "./components/Footer/Legacy/TermsPage";
import TribePage from "./pages/PlaygroundPage/TribePage/TribePage";
import SurfboardsPage from "./pages/ProductsPage/SurfboardsPage/SurfboardsPage";
import SurfboardDetailPage from "./pages/SurfboardDetailPage/SurfboardDetailPage";
import PaymentPage from "./pages/PaymentPage/PaymentPage.jsx";
import CheckoutPage from "./pages/PaymentPage/CheckoutPage/CheckoutPage";
import CorrectPaymentPage from "./pages/CorrectPaymentPage/CorrectPaymentPage";
import AddNewProduct from "./pages/AddNewProduct/AddNewProduct";
import StockPage from "./pages/StockPage/StockPage";


function Routes() {

    const [isLogged, setIsLogged] = useState(!!localStorage.getItem('token'));

    return (
        <Router>
            <Switch>
                <PrivateRoute exact path={PATH.NEW_PRODUCT}><AddNewProduct/></PrivateRoute>
                <PrivateRoute exact path={PATH.ADMIN}><AdminPage/></PrivateRoute>
                <PrivateRoute exact path={PATH.STOCK}><StockPage/></PrivateRoute>
                <Route exact path={PATH.LOGIN}><LoginPage isLogged={isLogged} setIsLogged={setIsLogged}/></Route>
                <Route path={PATH.ABOUT_US}><AboutUsPage/></Route>
                <Route path={PATH.SURFBOARDS + '/:title'}><SurfboardDetailPage/></Route>
                <Route path={PATH.SURFBOARDS}><SurfboardsPage/></Route>
                <Route path={PATH.WARDROBE + '/socks'}><SocksPage/></Route>
                <Route path={PATH.WARDROBE_SHIRTS}><TshirtsPage/></Route>
                <Route path={PATH.WARDROBE_WETSUITS}><WetsuitsPage/></Route>
                <Route path={PATH.WARDROBE + '/underwear'}><UnderwearPage/></Route>
                <Route path={PATH.WARDROBE_SWEAT}><SweatWear/></Route>
                <Route path={PATH.WARDROBE_CAPS}><HeadwearPage/></Route>
                <Route path={PATH.WARDROBE + '/:title/:color'}><DetailProductPage/></Route>
                <Route path={PATH.WARDROBE}><WardrobePage/></Route>
                <Route path={PATH.PLAYGROUND_FAMILY + '/:name/:surname'}><DetailTeamPage/></Route>
                <Route path={PATH.PLAYGROUND_FAMILY}><FamilyPage isLogged={isLogged}/></Route>
                <Route path={PATH.PLAYGROUND_TRIBE}><TribePage isLogged={isLogged}/></Route>
                <Route path={PATH.PLAYGROUND}><PlaygroundPage/></Route>
                <Route path={PATH.STORES}><FindYourShop/></Route>
                <Route path={PATH.RETURNS}><ReturnsPolicyPage/></Route>
                <Route path={PATH.SHIPPING_INFO}><ShippingInformationPage/></Route>
                <Route path={PATH.TERMS_COND}><TermsPage/></Route>
                <Route path={PATH.CART}><CartComponent/></Route>
                <Route path={PATH.CONTACT}><ContactingPage/></Route>
                <Route path={PATH.PAYMENT_CHECKOUT}><CheckoutPage/></Route>
                <Route path={PATH.PAYMENT_CORRECT}><CorrectPaymentPage/></Route>
                <Route path={PATH.PAYMENT}><PaymentPage/></Route>
                <Route exact path={PATH.HOME}><HomePage/></Route>
            </Switch>
        </Router>
    );
}

export default Routes;
