import React, {Suspense, useContext, useEffect, useState} from "react";
import {CartContext} from "../../components/Context/CartContext";
import {useHistory} from "react-router-dom";
import $ from "jquery";
import {Link, useParams} from "react-router-dom";
import {API, PATH} from "../../shared/services/api";
import Slider from "react-slick";
import {FaShoppingCart, GrClose, IoIosArrowDown, MdCheck} from "react-icons/all";
import {Accordion, Card} from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import Modal from "react-modal";
import {useTranslation} from "react-i18next";

export default function SurfboardDetailPage() {

    const history = useHistory();
    const [t, i18n] = useTranslation("global");

    const toContact = () => {
        history.push(PATH.CONTACT)
    }
    const {setCountCart} = useContext(CartContext);

    // jQuery for add to cart button
    (function() {
        var removeSuccess;

        removeSuccess = function() {
            return $('.button').removeClass('success');
        };

        $(document).ready(function() {
            return $('.button').click(function() {
                $(this).addClass('success');
                return setTimeout(removeSuccess, 2000);
            });
        });

    }).call(this);

    const title = useParams().title;
    const width = window.innerWidth;

    let settings = {
        dots: true,
        infinite: false,
        speed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        adaptiveHeight: true
    };

    const [product, setProduct] = useState(null);
    const [chosenSrc, setChosenSrc] = useState(null);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    //petitions for surfboards and title

    useEffect(() => {
        API.get('product/' + title).then(res => {
            setProduct(res.data);
            if (product !== null) {
                setChosenSrc(product[0].frontPictureJPG.tablet)
            }
        })
        localCart = JSON.parse(localCart);
        if(localCart) setCart(localCart)

    }, []);

    const modalPicture = () => {
        if (chosenSrc == null) {
            if (product[0].frontPictureJPG.web == ([] || '' || null)) {
                setChosenSrc(product[0].frontPictureJPG.tablet)
                console.log('choosing tablet over web')
            } else {
                setChosenSrc(product[0].frontPictureJPG.web)
                console.log('choosing web but there isnt')
            }
        } else if (chosenSrc == product[0].frontPictureJPG.tablet && product[0].frontPictureJPG.web !== null) {
                setChosenSrc(product[0].frontPictureJPG.web);
                console.log(chosenSrc)
            }
        setModalIsOpen(true);
    }


    const showImageBack = () => {
        if (product !== null) {
            setChosenSrc(product[0].backPictureJPG.tablet)
        }
    }

    function handleCloseModal() {
        setModalIsOpen(false);
    }

    const showImageFront = () => {
        if (product !== null) {
            setChosenSrc(product[0].frontPictureJPG.tablet)
        }
    }

    const showExtra1 = () => {
        if (product !== null) {
            setChosenSrc(product[0].additionalPictures[0])
        }
    }

    const showExtra2 = () => {
        if (product !== null) {
            setChosenSrc(product[0].additionalPictures[1])
        }
    }

    const showExtra3 = () => {
        if (product !== null) {
            setChosenSrc(product[0].additionalPictures[2])
        }
    }

    const showExtra4 = () => {
        if (product !== null) {
            setChosenSrc(product[0].additionalPictures[3])
        }
    }

    // shopping cart

    let [cart, setCart] = useState([]);
    let localCart = localStorage.getItem("cart");
    //when user check out i have to set localStorage.removeItem(“cart”)

    // Método para añadir elementos al carrito
    const addItem = (item) => {


        let select = document.getElementById('surfSelector');

        // Crear un objeto con la info del elemento que se quiere añadir
        let info = {
            id: item._id,
            productCode: item.productCode,
            title: item.title,
            price: item.price,
            size: select.value,
            frontPicture: item.frontPictureJPG.phone,
            quantity: 1,
            type: item.type,
        }
        let listItem = [info];
        let recoveredData = localStorage.getItem("cart");

        //Si no hay nada en el localstorage "Cart" que se cree con el elemento seleccionado
        if (recoveredData == null) {

            localStorage.setItem("cart", JSON.stringify(listItem));

            //Si ya existe el localstorage Cart
        } else {
            // Recuperar lo que hay el "cart". Buscar si el elemento que se quiere introducir ya existe y su indice.
            let data = JSON.parse(recoveredData);


            const existentItem = data.find((element) => element.id === info.id && element.color === info.color && element.size === info.size);
            const indexItem = data.findIndex((element) => element.id === info.id && element.color === info.color && element.size === info.size);


            // Si no hay ningun elemento en el cart igual al elemento que se quiere introducir se añade.
            if (existentItem === undefined) {

                data.push(info);

                // If there's already an exact element as the one we are introducing, modify property quantity
            } else {
                data[indexItem].quantity += 1;
            }


            // //Update the cart

            localStorage.setItem("cart", JSON.stringify(data));

        }

        // Cambiar la variable del contexto para que en el nav aparezcan el numero de elementos que hay en carrito.
        let value = 0;
        let cart = JSON.parse(localStorage.getItem("cart"));
        for (let element of cart) {

            value += element.quantity;
        }
        setCountCart(value);
    }


    return (
        <div>
            <div className="row col-12 detail">

                {/* view for tablets and phones */}
                {product && product[0].frontPictureJPG && width < 992 &&
                <div className="col-12 px-0 detail__slider-container">
                    <Slider {...settings}>
                        <img className="detail__picture" src={product[0].frontPictureJPG.phone} alt={product[0].title}/>
                        {product && product[0].backPictureJPG &&
                        <img className="detail__picture" src={product[0].backPictureJPG.phone} alt={product[0].title}/>
                        }
                        {product && product[0].additionalPictures && product[0].additionalPictures[0] &&
                        <img className="detail__picture-thumb" src={product[0].additionalPictures[0]} alt={product[0].title}/>
                        }
                        {product && product[0].additionalPictures && product[0].additionalPictures[1] &&
                        <img className="detail__picture-thumb" src={product[0].additionalPictures[1]} alt={product[0].title}/>
                        }
                        {product && product[0].additionalPictures && product[0].additionalPictures[2] &&
                        <img className="detail__picture-thumb" src={product[0].additionalPictures[2]} alt={product[0].title}/>
                        }
                    </Slider>
                </div>
                }

                {/* view for pc and big screens */}

                {product && product[0].frontPictureJPG && width > 992 &&
                <div className="col-12 col-lg-8 row detail__pics-container">
                    <div id="thumbnail-scroll" className="col-2 detail__thumbnail-container">
                        <img id="frontPic" className="detail__picture-thumb" onClick={showImageFront}
                             src={product[0].frontPictureJPG.phone}/>
                        {product && product[0].backPictureJPG &&
                        <img id="backPic" className="detail__picture-thumb" onClick={showImageBack}
                             src={product[0].backPictureJPG.phone} alt={product[0].title}/>
                        }

                        {/* additional pictures displayed */}

                        {product && product[0].additionalPictures && product[0].additionalPictures[0] &&
                        <img id="extra1" className="detail__picture-thumb" onClick={showExtra1}
                             src={product[0].additionalPictures[0]} alt={product[0].title}/>
                        }
                        {product && product[0].additionalPictures && product[0].additionalPictures[1] &&
                        <img id="extra2" className="detail__picture-thumb" onClick={showExtra2}
                             src={product[0].additionalPictures[1]} alt={product[0].title}/>
                        }
                        {product && product[0].additionalPictures && product[0].additionalPictures[2] &&
                        <img id="extra3" className="detail__picture-thumb" onClick={showExtra3}
                             src={product[0].additionalPictures[2]} alt={product[0].title}/>
                        }
                        {product && product[0].additionalPictures && product[0].additionalPictures[3] &&
                        <img id="extra4" className="detail__picture-thumb" onClick={showExtra4}
                             src={product[0].additionalPictures[3]} alt={product[0].title}/>
                        }
                    </div>
                    <div className="col-9 detail__main-container">
                        {chosenSrc !== null &&
                        <Suspense fallback={<p>Product</p>}>
                            <img onClick={() => setModalIsOpen(true)} className="detail__picture" src={chosenSrc}
                                 alt="Main product"/>
                        </Suspense>

                        }
                        {chosenSrc == null &&
                        <img onClick={modalPicture} className="detail__picture"
                             src={product[0].frontPictureJPG.tablet} alt="Main product"/>
                        }
                    </div>

                    <Modal id="surf-amplifier" isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
                        {chosenSrc !== null &&
                        <img className="detail__modal__picture" src={chosenSrc} alt={product[0].title}/>
                        }
                        <button className="sidebar__close amplifier" onClick={() => setModalIsOpen(false)}><GrClose/></button>

                    </Modal>

                </div>
                }
                {product &&
                <div className="col-12 col-lg-3 detail__info-container">
                    <p className="detail__product-title">
                        {title !== 'Eleanor' &&
                        <span>THE </span>
                        }
                        {title} {product[0].type}</p>
                    <p className="detail__product-desc__price">{product[0].price} €</p>
                    <div className="detail__product-desc-container column px-0">
                        <p className="detail__product-desc">{t('products.size')}:</p>
                        {product[0].surfSizes &&
                        <select className="row mx-0 px-3 py-2" id="surfSelector">

                            {product[0].availableSizes.uniqueSize < 1 &&
                                <option disabled selected value="">SOLD OUT</option>
                            }

                            {/* for any surfboard: enabled size FOR SELL when > 0 */}

                            {product[0].surfSizes[0]&&
                            <option disabled value={product[0].surfSizes[0]}>{product[0].surfSizes[0]}</option>
                            }

                            {/* for glider surfboard: if unique size FOR SELL is 0, show as disabled, otherwise enabled */}

                            {product[0].surfSizes[1] && product[0].title == 'Glider' && product[0].availableSizes.uniqueSize > 0 &&
                            <option value={product[0].surfSizes[1]}>{product[0].surfSizes[1]}</option>
                            }

                            {product[0].surfSizes[1] && product[0].title == 'Glider' && product[0].availableSizes.uniqueSize < 1 &&
                            <option disabled value={product[0].surfSizes[1]}>{product[0].surfSizes[1]}</option>
                            }




                            {product[0].surfSizes[1] && product[0].title !== 'Glider' &&
                            <option disabled value={product[0].surfSizes[1]}>{product[0].surfSizes[1]}</option>
                            }
                            {product[0].surfSizes[2] &&
                            <option disabled value={product[0].surfSizes[2]}>{product[0].surfSizes[2]}</option>
                            }


                            {/* for gotham and smurf surfboard: if size FOR SELL is 0, show as disabled, otherwise enabled */}

                            {product[0].surfSizes[3] && (product[0].title == 'Gotham' || product[0].title == 'Smurf') &&
                            <option value={product[0].surfSizes[3]}>{product[0].surfSizes[3]}</option>
                            }
                            {product[0].surfSizes[3] && (product[0].title == 'Gotham' || product[0].title == 'Smurf') && product[0].availableSizes.uniqueSize < 1 &&
                            <option disabled value={product[0].surfSizes[3]}>{product[0].surfSizes[3]}</option>
                            }


                            {product[0].surfSizes[3] && product[0].title !== 'Gotham' && product[0].title !== 'Smurf' &&
                            <option disabled value={product[0].surfSizes[3]}>{product[0].surfSizes[3]}</option>
                            }

                            {/* for eleanor surfboard: if size FOR SELL is 0, show as disabled, otherwise enabled */}

                            {product[0].surfSizes[4] && product[0].title == 'Eleanor' &&
                            <option value={product[0].surfSizes[4]}>{product[0].surfSizes[4]}</option>
                            }
                            {product[0].surfSizes[4] && product[0].title == 'Eleanor' && product[0].availableSizes.uniqueSize < 1 &&
                            <option disabled value={product[0].surfSizes[4]}>{product[0].surfSizes[4]}</option>
                            }


                            {product[0].surfSizes[4] && product[0].title !== 'Eleanor' &&
                            <option disabled value={product[0].surfSizes[4]}>{product[0].surfSizes[4]}</option>
                            }
                            {product[0].surfSizes[5] &&
                            <option disabled value={product[0].surfSizes[5]}>{product[0].surfSizes[5]}</option>
                            }
                            {product[0].surfSizes[6] &&
                            <option disabled value={product[0].surfSizes[6]}>{product[0].surfSizes[6]}</option>
                            }
                        </select>
                        }
                        <p onClick={toContact} className="detail__product-desc request">*{t('products.other-sizes')}</p>
                    </div>

                    { product[0].availableSizes.uniqueSize > 0 &&

                        <button onClick={() => addItem(product[0])} className="button" role="button">
                            <span>{t('products.add-to-cart')}</span>
                            <div className="icon">
                                <FaShoppingCart className="fa-remove shop-icon-cart"/>
                                <MdCheck className="fa-check shop-icon"/>
                            </div>
                        </button>
                    }


                    {product[0].description &&
                        <div>
                            <p className="detail__product-desc">{t('products.pre-shaper')} <span className="font-weight-bold">shaper</span> {t('products.post-shaper')}</p>
                            <p className="detail__product-desc board">{product[0].description}</p>
                        </div>

                    }
                    <Accordion className="detail__product__accordion">
                        {product[0].composition &&
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                <div className="row justify-content-between">
                                    <span>Composition</span>
                                    <span><IoIosArrowDown/></span>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    {product && product[0].composition && product[0].composition.percentage &&
                                    <p>{product[0].composition.percentage}</p>
                                    }
                                    {product && product[0].composition && product[0].composition.grams &&
                                    <span>{product[0].composition.grams}</span>
                                    }
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        }
                        {product[0].careInfo &&
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                                <div className="row justify-content-between">
                                    <span>Care Information</span>
                                    <span><IoIosArrowDown/></span>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>{product[0].careInfo}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        }
                    </Accordion>
                </div>
                }
            </div>
            <Suspense fallback={<p>The Voodoo Brand...</p>}>
                <Footer/>
            </Suspense>
        </div>
    );
}
