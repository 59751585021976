import React from 'react';
import axios from 'axios';
import "./Search.scss";
import {BACK_URL, PATH} from "../../shared/services/api";

class Search extends  React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            query: '',
            results: {},
            loading: false,
            message: '',
        };
        this.cancel = '';
    }
    /**
     * Fetch the search results and update the state with the result.
     *
     * @param {int} updatedPageNo Updated Page No.
     * @param {String} query Search Query.
     *
     */


    fetchSearchResults = (query ) => {
        const capitalizedQuery =  query.charAt(0).toUpperCase() + query.slice(1);
        const searchUrl = BACK_URL+`product/search/${capitalizedQuery}`;
        console.log(BACK_URL+`product/search/${capitalizedQuery}`)
        if (this.cancel) {
            // Cancel the previous request before making a new request
            this.cancel.cancel();
        }
        // Create a new CancelToken
        this.cancel = axios.CancelToken.source();
        axios
            .get(searchUrl, {
                cancelToken: this.cancel.token,
            })
            .then((res) => {
                const resultNotFoundMsg = !res.data
                    ? 'No search results. Please try a new search.'
                    : '';
                this.setState({
                    results: res.data,
                    message: resultNotFoundMsg,
                    loading: false,
                });
            })
            .catch((error) => {
                if (axios.isCancel(error) || error) {
                    this.setState({
                        loading: false,
                        message: 'Please try a new search',
                    });
                }
            });
    };


    handleOnInputChange = (event) => {
        const query = (event.target.value);
        if ( ! query ) {
            this.setState({ query, results: {}, message: '' } );
        } else {
            this.setState({ query, loading: true, message: '' }, () => {
                this.fetchSearchResults(query);
            });
        }
    };


    toProduct = (productTitle, productType, productColor) => {
        if (productType == 'Surfboard') {
            window.location.assign(PATH.SURFBOARDS + '/' + productTitle)
        } else {
            window.location.assign(PATH.WARDROBE + '/' + productTitle + '/' + productColor)
        }
    }

    renderSearchResults = () => {
        const {results} = this.state;
        if (Object.keys(results).length && results.length) {
            return (
                <div id="search-scroll" className="search__results-container row mx-0">
                    {results.map((result) => {
                        return (
                            <div id="search__results-link" key={result.id}  onClick={() => this.toProduct(result.title, result.type, result.color)}>
                                <div className="col-10 search__results-row">
                                    <p className="search__results-title">{result.title} {result.type}</p>

                                    <img className="search__results-picture" src={result.frontPicture.phone} alt={result.title}/>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }
    };

    render() {
        const { query, message } = this.state;

        return (
            <div id="search-scroll" className="search">

                {/*Heading*/}
                <h3>What are you looking for?</h3>

                {/*Search Input*/}
                <label htmlFor="search-input">
                    <input className="search__input"
                        type="text"
                        value={query}
                        id="search-input"
                        placeholder="Search products"
                        onChange={this.handleOnInputChange}
                    />
                    <i className="fa fa-search search-icon"/>
                </label>

                {/*Error Message*/}
                { message && <p className="message">{message}</p> }

                {/*Result*/}
                { this.renderSearchResults() }

            </div>
        )
    }

}
export default Search;
