import React, {useState, useEffect} from "react";
import {CardElement, useStripe, useElements} from "@stripe/react-stripe-js";
import {BACK_URL, LOCALHOST, PATH} from "../../shared/services/api";
import "./CheckoutForm.scss";

export default function CheckoutForm(props) {

    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    const [shippingPrice, setShippingPrice] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [customer, setCustomer] = useState({});

    useEffect(() => {
        let cart = JSON.parse(localStorage.getItem("cart"));
        let customer = JSON.parse(localStorage.getItem("customer"));
        setCustomer(customer);
        // Create PaymentIntent as soon as the page loads
        //Watch for the initial / in the url.

        window
            .fetch(BACK_URL + "stripe/create-payment-intent", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({items: cart, customer: customer})
            })
            .then(res => {
                return res.json();
            })
            .then(data => {
                setClientSecret(data.clientSecret);
                setShippingPrice(data.shippingPrice / 100);
                setTotalAmount(data.totalAmount / 100);
                props.sendTotal((data.totalAmount / 100));
                props.sendShipping((data.shippingPrice) / 100);
            });
    }, []);

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    }

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    }

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);
        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: customer.name + ' ' + customer.surname,
                    email: customer.email,
                    address: {
                        city: customer.city,
                        line1: customer.street,
                        line2: customer.address_extra || '',
                        postal_code: customer.postalCode
                    }
                }
            }
        });
        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            console.log('payload error')
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            let paid = {
                shipping: shippingPrice,
                totalAmount: totalAmount+shippingPrice,
                subtotal: totalAmount
            };
            if (localStorage.getItem('paid')) {
                localStorage.removeItem('paid')
            }
            localStorage.setItem("paid", JSON.stringify(paid));
            window.location.assign(PATH.PAYMENT_CORRECT)
        }
    }

    return (
        <div>
            {/*<h1>HOLA, FELIX I ANNA</h1>*/}
            {/*<h3>Podeu provar un pagament fictici amb la següent visa:</h3>*/}
            {/*<h3>4242 4242 4242 4242 - qualsevol data - qualsevol codi postal</h3>*/}
            {/*<p>posteriorment podeu anar al vostre USUARI de stripe</p>*/}
            {/*<span>i veure com al dashboard apareixen el pagament</span>*/}
            {/*<span>(a veure datos de prueba)</span>*/}
            {totalAmount !== 0 &&
            <form id="payment-form" onSubmit={handleSubmit}>
                <CardElement id="card-element" options={cardStyle} onChange={handleChange}/>
                <button disabled={processing || disabled || succeeded} id="submit">
                <span id="button-text">
                  {processing ? (
                      <div className="spinner" id="spinner"></div>
                  ) : (
                      "Pay now"
                  )}
                </span>
                </button>
                {/* Show any error that happens when processing the payment */}
                {error && (
                    <div className="card-error" role="alert">
                        {error}
                    </div>
                )}
                {/* Show a success message upon completion */}
                <p className={succeeded ? "result-message" : "result-message hidden"}>
                    <a
                        href={`https://dashboard.stripe.com/test/payments`}
                    >
                    </a>
                </p>
            </form>
            }
        </div>

    );
}
