import React, {useEffect, useState} from "react";
import {API} from "../../../shared/services/api";
import Footer from "../../../components/Footer/Footer";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function HeadWear() {

    const [caps, setCaps] = useState(null);
    const [beanies, setBeanies] = useState([]);
    const width = window.innerWidth;
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
            API.get('product/caps').then(res =>
                setCaps(res.data)
            )
            API.get('product/beanies').then(res =>
                setBeanies(res.data)
            )
        },
        [])

    return (

        <div className="product__img__gallery-container">
            <p className="product__img__black-text center">{t('products.caps-col')}</p>

            {caps &&
            <div className="col-12 row mx-0 px-0 product__img__big-container">
                <div className="col-12 col-md-6 col-xl-3 product__img__container">
                    <Link to={"/wardrobe/" + caps[0].title + '/' + caps[0].color} className="product__img__link">

                        <picture>
                            <source type="image/webp" srcSet={caps[0].frontPicture.phone} alt={caps[0].title} className="product__img__gallery-section" />
                            <img  className="product__img__gallery-section"  src={caps[0].frontPictureJPG.phone} alt={caps[0].title}/>
                        </picture>

                    </Link>
                    <p className="product__img__black-text custom-typo">{caps[0].title}</p>
                    <p className="product__img__price">{caps[0].price} €</p>
                    <div className="product__img__balls">
                        <div className={caps[0].color}></div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-xl-3 product__img__container">
                    <Link to={"/wardrobe/" + caps[1].title + '/' + caps[1].color} className="product__img__link">

                        <picture>
                            <source type="image/webp" srcSet={caps[1].frontPicture.phone} alt={caps[1].title} className="product__img__gallery-section" />
                            <img  className="product__img__gallery-section"  src={caps[1].frontPictureJPG.phone} alt={caps[1].title}/>
                        </picture>

                    </Link>
                    <p className="product__img__black-text custom-typo">{caps[1].title}</p>
                    <p className="product__img__price">{caps[1].price} €</p>
                    <div className="product__img__balls">
                        <div className={caps[1].color}></div>
                        <div className={caps[2].color}></div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-xl-3 product__img__container">
                    <Link to={"/wardrobe/" + caps[4].title + '/' + caps[4].color} className="product__img__link">

                        <picture>
                            <source type="image/webp" srcSet={caps[4].frontPicture.phone} alt={caps[4].title} className="product__img__gallery-section" />
                            <img  className="product__img__gallery-section"  src={caps[4].frontPictureJPG.phone} alt={caps[4].title}/>
                        </picture>

                    </Link>
                    <p className="product__img__black-text custom-typo">{caps[4].title}</p>
                    <p className="product__img__price">{caps[4].price} €</p>
                    <div className="product__img__balls">
                        <div className={caps[3].color}></div>
                        <div className={caps[4].color}></div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-xl-3 product__img__container">
                    <Link to={"/wardrobe/" + caps[6].title + '/' + caps[6].color} className="product__img__link">

                        <picture>
                            <source type="image/webp" srcSet={caps[6].frontPicture.phone} alt={caps[6].title} className="product__img__gallery-section" />
                            <img  className="product__img__gallery-section"  src={caps[6].frontPictureJPG.phone} alt={caps[6].title}/>
                        </picture>

                    </Link>
                    <p className="product__img__black-text custom-typo">{caps[6].title}</p>
                    <p className="product__img__price">{caps[6].price} €</p>
                    <div className="product__img__balls">
                        <div className={caps[5].color}></div>
                        <div className={caps[6].color}></div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-xl-3 product__img__container">
                    <Link to={"/wardrobe/" + caps[7].title + '/' + caps[7].color} className="product__img__link">

                        <picture>
                            <source type="image/webp" srcSet={caps[7].frontPicture.phone} alt={caps[7].title} className="product__img__gallery-section" />
                            <img  className="product__img__gallery-section"  src={caps[7].frontPictureJPG.phone} alt={caps[7].title}/>
                        </picture>

                    </Link>
                    <p className="product__img__black-text custom-typo">{caps[7].title}</p>
                    <p className="product__img__price">{caps[7].price} €</p>
                    <div className="product__img__balls">
                        <div className={caps[7].color}></div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-xl-3 product__img__container">
                    <Link to={"/wardrobe/" + caps[8].title + '/' + caps[8].color} className="product__img__link">
                        <picture>
                            <source type="image/webp" srcSet={caps[8].frontPicture.phone} alt={caps[8].title} className="product__img__gallery-section" />
                            <img  className="product__img__gallery-section"  src={caps[8].frontPictureJPG.phone} alt={caps[8].title}/>
                        </picture>
                    </Link>
                    <p className="product__img__black-text custom-typo">{caps[8].title}</p>
                    <p className="product__img__price">{caps[8].price} €</p>
                    <div className="product__img__balls">
                        <div className={caps[8].color}></div>
                        <div className={caps[9].color}></div>
                    </div>
                </div>
            </div>
            }
                <p className="product__img__black-text center">{t('products.beanies-col')}</p>
                <div className="col-12 row mx-0 product__img__big-container">

                {/* beanies loop */}

                {beanies.map((beanie, i) =>
                    <div className="col-12 col-md-6 col-xl-3 product__img__container">
                        {width < 550 &&
                        <Link to={"/wardrobe/" + beanie.title + '/' + beanie.color} className="product__img__link">

                            <picture>
                                <source type="image/webp" srcSet={beanie.frontPicture.phone} alt={beanie.title} className="product__img__gallery-section" />
                                <img  className="product__img__gallery-section"  src={beanie.frontPictureJPG.phone}alt={beanie.title}/>
                            </picture>

                        </Link>
                        }
                        {width > 550 &&
                        <Link to={"/wardrobe/" + beanie.title + '/' + beanie.color} className="product__img__link">

                            <picture>
                                <source type="image/webp" srcSet={beanie.frontPicture.tablet} alt={beanie.title} className="product__img__gallery-section" />
                                <img  className="product__img__gallery-section"  src={beanie.frontPictureJPG.tablet}alt={beanie.title}/>
                            </picture>
                        </Link>
                        }
                        <p className="product__img__black-text custom-typo">{beanie.title}</p>
                        <p className="product__img__price">{beanie.price} €</p>
                    </div>
                )}
                </div>
            <Footer/>
        </div>
    )
}
