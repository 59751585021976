import React from "react";
import "./Footer.scss";
import {useHistory} from "react-router-dom";
import {PATH} from "../../shared/services/api";
import {useTranslation} from "react-i18next";

export default function Footer() {

    const history = useHistory();
    const [t, i18n] = useTranslation("global");

    const toAboutUs = () => {
        history.push('/about')
    }

    const toContact = () => {
        history.push('/contact')
    }

    const toStores = () => {
        history.push('/stores')
    }

    const toReturns = () => {
        history.push('/returns')
    }

    const toShipping = () => {
        history.push('/shipping')
    }

    const toTermsConditions = () => {
        history.push(PATH.TERMS_COND);
    }

    const instagram = () => {
        window.open('https://www.instagram.com/thevoodoobrand/')
    }

    const facebook = () => {
        window.open('https://www.facebook.com/Thevoodoobrand-107916997551187')
    }

    const spotify = () => {
        window.open('https://open.spotify.com/playlist/4npvjdLbbkbN6DBQcHbfDx?si=OjR_1_JPTfKVrwwCJ6MXbQ')
    }

    return (
        <div className="footer centered">
            <h2 className="footer__voodoo">The Voodoo Brand © 2021</h2>
            <div className="montserrat__link-container">
                <p onClick={toAboutUs} className="montserrat__link">{t('footer.about-us')}</p>
                <p onClick={toContact} className="montserrat__link">{t('footer.contact')}</p>
                <p onClick={toStores} className="montserrat__link">{t('footer.find-shop')}</p>
            </div>
            <p className="montserrat__join">{t('footer.join-tribe')}</p>
            <div className="icon__container">
                <svg onClick={facebook} className="icon" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/></svg>
                <svg onClick={instagram} className="icon" fill="#FFFFFF"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                <svg onClick={spotify} className="icon" fill="#FFFFFF" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z"/></svg>
            </div>
            <p onClick={toReturns} className="footer__conditions">{t('footer.returns')}</p>
            <p onClick={toShipping} className="footer__conditions">{t('footer.shipping')}</p>
            <p onClick={toTermsConditions} className="footer__conditions">{t('footer.terms-conditions')}</p>
        </div>
    )
}
