import React, {useEffect, useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "../../../components/CheckoutForm/CheckoutForm";
//const promise = loadStripe('pk_test_51INLKqGIRiUvGwl0LPwhdV485EroNhtWpeYWL4b7cszJSECHkecKxjVwWglhXkYNWRG9SqqXDowpNZhBjBM7gg4d003JcsteJ6')
const promise = loadStripe('pk_live_51INLKqGIRiUvGwl0P4b16xIZWbk9uXbJsLIbyExpHVTgsUylNSZKVQoe3qBmbdm8LDS5flrk6cHhv6Rd2fxI03Z700I6yhH1B9');

export default function PaymentPage() {

    useEffect(() => {
        let cart = JSON.parse(localStorage.getItem("cart"));
        let customer = JSON.parse(localStorage.getItem("customer"));
        setCart(cart);
        setCustomer(customer)
    }, [])

    const [cart, setCart] = useState([]);
    const [customer, setCustomer] = useState([]);

    let [shippingPrice, setShippingPrice] = useState(0);
    let [totalAmount, setTotalAmount] = useState(0);


    return (
        <div>
            <div className="col-12 px-0 row mx-0">
                {/* STRIPE CHECKOUT */}
                <div className="col-12 col-sm-6 px-0 payment__phases">
                    <div>
                        <h2 className="payment__title">SHIPPING DETAILS</h2>
                        <p>Name and surname/s:</p>
                        <p className="montserrat">{customer.name} {customer.surname}</p>
                        <p>Phone:</p>
                        <p className="montserrat">{customer.phone}</p>
                        <p>Email:</p>
                        <p className="montserrat">{customer.email}</p>
                        <p>Shipping address:</p>
                        <p className="montserrat">{customer.street}{customer.address_extra && ', ' + customer.address_extra}, {customer.city}, {customer.country} - {customer.postalCode}</p>
                        <Elements stripe={promise}>
                            <CheckoutForm shippingToChild={shippingPrice}
                                          totalAmountToChild={totalAmount}
                                          sendTotal={setTotalAmount}
                                          sendShipping={setShippingPrice}
                            />
                        </Elements>
                    </div>
                </div>
                <div className="col-12 col-sm-6 px-0 payment__pricing">
                    <h2 className="payment__title">YOUR PRODUCTS</h2>
                    {cart.map((item, i) =>
                        <div className="payment__product-container">
                            <img className="payment__product-thumbnail" src={item.frontPicture} alt=""/>
                            <div className="payment__prices-container">
                                <p className="align-self-end">{item.title} x {item.quantity}</p>
                                <p className="align-self-end">{item.size}{item.surfSize}</p>
                                <p className="align-self-end">Unit price: {item.price} €</p>
                            </div>
                        </div>
                    )}
                    <div className="payment__product-container prices">
                        <span>Subtotal:</span><span className="montserrat">{totalAmount} €</span>
                    </div>
                    <div className="payment__product-container">
                        <span>Shippings:</span>
                        <span className="montserrat">{shippingPrice} €</span>
                    </div>
                    <div className="payment__product-container">
                        <h3>Total:</h3><h3>{totalAmount+shippingPrice} €</h3>
                    </div>
                </div>
            </div>
        </div>

    );
}
