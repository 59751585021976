import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {PATH} from "../../shared/services/api";
import "./WardrobePage.scss";
import Footer from "../../components/Footer/Footer";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

export default function WardrobePage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    const [t, i18n] = useTranslation("global");

    const toTshirts = () => {
        history.push(PATH.WARDROBE_SHIRTS)
    }

    const toCaps = () => {
        history.push(PATH.WARDROBE_CAPS)
    }

    // const toSocks = () => {
    //     history.push('/wardrobe/socks')
    // }

    const toSweatshirts = () => {
        history.push(PATH.WARDROBE_SWEAT)
    }

    // const toWetsuits = () => {
    //     history.push('/wardrobe/wetsuits')
    // }

    return (
        <div>
            <div className="wardrobe row px-0 col-12 mx-0">
                <div onClick={toTshirts} className="wardrobe__cat-container tshirts col-12 col-sm-6 px-0">
                    <h1 className="wardrobe__title">{t('products.t-shirts')}</h1>
                </div>
                <div onClick={toSweatshirts} className="wardrobe__cat-container sweat col-12 col-sm-6 px-0">
                    <h1 className="wardrobe__title">{t('products.sweatshirts')}
                        <br/>& <br/>{t('products.hoodies')}</h1>
                </div>
                <div onClick={toCaps} className="wardrobe__cat-container caps  col-12 col-sm-6 px-0">
                    <h1 className="wardrobe__title caps">{t('products.caps')}
                        <br/>& <br/>{t('products.beanies')}</h1>
                </div>
                <div className="wardrobe__cat-container wetsuits  col-12 col-sm-6 px-0">
                    <h1 className="wardrobe__title">{t('products.wetsuits')}</h1>
                    <p className="coming">{t('products.coming-soon')}</p>
                </div>
            </div>
            <Footer/>
        </div>

    );
}
