import React, {useEffect, useState} from "react";
import "./AdminPage.scss";
import {API, PATH} from "../../shared/services/api";
import {Accordion, Card} from "react-bootstrap";
import {IoIosArrowDown} from "react-icons/all";
import {useForm} from "react-hook-form";

export default function AdminPage() {

    const {register, handleSubmit, errors} = useForm();
    const [orderSearch, setOrderSearch] = useState(null);
    const [totalSold, setTotalSold] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("token");

    }, []);

    const onSubmit = formData => {
        API.get('order/' + formData.year + '/' + formData.month)
            .then(res => {
                console.log(res.data);
                setOrderSearch(res.data)
                let total = 0;
                {res.data.map((sold, i) =>
                    total += sold.totalAmount
                )}
                setTotalSold(total);
                console.log(total)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const logOut = () => {
        localStorage.removeItem('token');
        window.location.assign(PATH.HOME)
    }

    return (
        <div className="register">
            <h1 className="blackc">ÁREA PRIVADA DE VOODOO</h1>
            <p>Ahora que estáis loggeados, tenéis acceso a controles privados</p>

            <h3>BÚSQUEDA DE PEDIDOS por fecha EN LA BASE DE DATOS:</h3>

            <form onSubmit={handleSubmit(onSubmit)} className="col-12 col-md-4">
                <label className="" htmlFor="address_extra">Mes</label>
                <input className="customer-form__input" type="text" name="month"
                       ref={register({required: true, pattern: /^[0-9]{1,2}[:.,-]?$/})}/>
                {errors.month &&
                <p className="customer-form__warning"> Mes tiene que ser un número sin 0 delante</p>}

                <label className="" htmlFor="address_extra">Año</label>
                <input className="customer-form__input" type="text" name="year"
                       ref={register({required: true, pattern: /^[0-9]{1,4}[:.,-]?$/})}/>
                {errors.year &&
                <p className="customer-form__warning"> Año tiene que ser un número de 4 dígitos </p>}

                <input className="login__button shippings" type="submit" value="Buscar"/>

            </form>
            {orderSearch &&
                <div className="col-12 px-0">
                    {totalSold &&
                    <h3>Total facturado en el mes de búsqueda: <span className="montserrat">{totalSold} €</span></h3>
                    }
                    <div className="row col-12 justify-content-center">
                        {orderSearch.length === 0 &&
                            <p>No hay resultados para el mes y/o año escogido </p>
                        }
                        {orderSearch.slice(0).reverse().map((result, i) =>
                            <Accordion className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey={result._id}>
                                        <div className="row justify-content-between">
                                            <span>{result.purchaseCode}</span>
                                            <span><IoIosArrowDown/></span>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={result._id}>
                                        <Card.Body className="justify-content-left">
                                            <p>email: <span className="montserrat">{result.email}</span></p>
                                            <p>address: <span className="montserrat">{result.address} ({result.postalCode && result.postalCode}), {result.city} - {result.country}</span></p>
                                            <p>phone: <span className="montserrat">{result.phone && result.phone}</span></p>
                                            <p>date: <span className="montserrat">{result.purchaseDay}/{result.purchaseMonth}/{result.purchaseYear}</span></p>
                                            <p>subtotal: <span className="montserrat">{result.subtotal} €</span></p>
                                            <p>shipping: <span className="montserrat">{result.shipping} €</span></p>
                                            <p>total: <span className="montserrat">{result.totalAmount} €</span></p>
                                            {result.products.map((item, i) =>
                                                <p>product: <span className="montserrat">{item.title} x {item.quantity} - <span className="font-weight-bold">Talla:</span> {item.size} - <span className="font-weight-bold">Precio:</span> {item.price}</span></p>
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        )}
                    </div>
                </div>

            }

            <button onClick={logOut} className="login__button">LOG OUT</button>
        </div>
    )
}
