import React from "react";

export default function UnderwearPage() {
    return (
        <div>
            <div className="product-text centered">
                <h1>Aquí aniran les fotos de LA ROBA INTERIOR</h1>
                <p>Decidir visualització (número de fotos per fila)</p>
            </div>
        </div>

    )
}
