import React from "react";

export default function WetsuitsPage() {
    return (
        <div>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
            <h1>WETSUITS</h1>
        </div>
    )
}
