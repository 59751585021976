import React from "react";

export default function SocksPage() {
    return (
        <div className="product__img__gallery-container">
            <div className="product-text centered">
                <h1>Aquí aniran les fotos dels MITJONS</h1>
                <p>Decidir visualització (número de fotos per fila)</p>
            </div>
        </div>

    )
}
