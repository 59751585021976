import React, {useState, useEffect, useContext} from "react";
import "./CartComponent.scss";
import {useTranslation} from "react-i18next";
import {CartContext} from "../../components/Context/CartContext";
import {IoTrashOutline} from "react-icons/all";
import {BiPlusCircle} from "react-icons/all";
import {BiMinusCircle} from "react-icons/all";
import {PATH} from "../../shared/services/api";

export default function CartComponent(props) {

    const [t, i18n] = useTranslation("global");
    const {countCart, setCountCart} = useContext(CartContext);
    const [items, setItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(null);

    useEffect(() => {
        setItems(JSON.parse(localStorage.getItem("cart")));
        updatePrice();
    }, []);


    function updatePrice() {
        if (JSON.parse(localStorage.getItem("cart"))) {
            let items = JSON.parse(localStorage.getItem("cart"));
            console.log(items)
            let total = 0;
            for (let i = 0; i < items.length; i++) {
                total += (items[i].quantity * items[i].price);
                console.log(total);
                setTotalPrice(total);
                props.sendTotal(total);
                console.log(total);
            }
        } else {
            props.sendTotal(0);
        }
    }

    // console.log(totalPrice)
    // console.log(items)

// Method to change the context's variable to show the number of cart elements in the nav.

    function changeNav() {
        let cart = JSON.parse(localStorage.getItem("cart"));
        let value = 0;
        if (cart) {
            for (let element of cart) {
                value += element.quantity;
            }
        }
        setCountCart(value);
        updatePrice();
    }

    // Method to erase the element
    function deleteItem(itemDelete) {
        let updateList = [];
        // Move cart products to another array except the one that I want to erase.
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            if (itemDelete !== item) {
                updateList.push(item);
            }

        }
        // Update the cart localstorage
        localStorage.setItem("cart", JSON.stringify(updateList));
        // Again, check the updated localstorage and if affirmative, erase the cart localstorage.
        let cart = JSON.parse(localStorage.getItem("cart"));
        if (cart.length > 0) {
            setItems(cart);
        } else {
            localStorage.removeItem("cart");
            setItems(cart);
        }
        changeNav();

    }

    function addQuantity(itemUpdate) {
        const updateItems = items.map((item) => {
            if (item === itemUpdate) {
                return {
                    ...item,
                    quantity: item.quantity + 1
                }
            }
            return item
        });
        setItems(updateItems);
        localStorage.setItem("cart", JSON.stringify(updateItems));
        changeNav();

    }

    function reduceQuantity(itemUpdate) {
        const updateItems = items.map((item) => {
            if (item === itemUpdate) {
                return {
                    ...item,
                    quantity: item.quantity - 1
                }
            }
            return item
        });
        setItems(updateItems);
        localStorage.setItem("cart", JSON.stringify(updateItems));
        changeNav();

    }


    const toProduct = (productTitle, productType, productColor) => {
        if (productType === 'Surfboard') {
            window.location.assign(PATH.SURFBOARDS + '/'  + productTitle)
            console.log(productType)
        } else {
            window.location.assign(PATH.WARDROBE + '/' + productTitle + '/' + productColor)
        }
    }

    return (
        <div className="cart col-12">
            <p className="cart__summary">{countCart} {t('cart.items-cart')}</p>
            <div id="thumbnail-cart-scroll" className="cart__element-container">
                {items &&
                <div>
                    {items.map(item =>
                        <div className="row col-12 mx-0 cart__element">
                            <div className="col-6 px-0">
                                <img className="cart__thumbnail" src={item.frontPicture} alt={item.title} onClick={() => toProduct(item.title, item.type, item.color)}/>
                            </div>
                            <div className="col-6 px-2">
                                <div className="cart__title-trash-container">
                                    <h5>{item.title}</h5>
                                    <button onClick={() => deleteItem(item)} role="button">
                                        <IoTrashOutline/>
                                    </button>
                                </div>
                                <p className="cart__price">{item.price} €</p>
                                <span className="cart__size">{item.size}</span>
                                {item.size === 'Unique' &&
                                <span className="cart__size"> size</span>
                                }
                                <div className="col-12 cart__adding-btn-container">
                                    {item.quantity > 1 &&
                                        <BiMinusCircle className="cart__adding-size"  onClick={() => reduceQuantity(item)} role="button"/>
                                    }
                                    <span className="cart__quantity">{t('cart.quantity')}: {item.quantity}</span>
                                    <BiPlusCircle className="cart__adding-size" onClick={() => addQuantity(item)} role="button"/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                }
            </div>
        </div>
    )
}
