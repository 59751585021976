import React from "react";
import Slider from "react-slick";

export default function SliderWeb() {

    const welcome = 'Welcome to Voodoo'

    let settings = {
        dots: false,
        infinite: true,
        speed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        adaptiveHeight: true
    };

    return (
        <div>
            <div className="slider__container">
                <Slider {...settings}>
                    <picture id="1-web">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371700/webP/slider/web/Slider_1_bbty4j.webp" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420677/PNG_JPG/slider/web/Slider_1_biyiuz.png" atl={welcome}/>
                    </picture>

                    <picture id="2-web">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371700/webP/slider/web/Slider_2_m28ukl.webp" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420670/PNG_JPG/slider/web/Slider_2_iplpte.png" atl={welcome}/>
                    </picture>

                    <picture id="3-web">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371700/webP/slider/web/Slider_3_vavv4i.webp" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420669/PNG_JPG/slider/web/Slider_3_iss9mt.png" atl={welcome}/>
                    </picture>

                    <picture id="4-web">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371700/webP/slider/web/Slider_4_yl12cl.webp" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420675/PNG_JPG/slider/web/Slider_4_q36kcj.png" atl={welcome}/>
                    </picture>

                    <picture id="5-web">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371700/webP/slider/web/Slider_5_epolvr.webp" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420671/PNG_JPG/slider/web/Slider_5_vaje7e.png" atl={welcome}/>
                    </picture>

                    <picture id="6-web">
                        <source type="image/webp" srcSet="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627371701/webP/slider/web/Slider_6_emtzko.webp" alt={welcome} className="slider__img"/>
                        <img className="slider__img" src="https://res.cloudinary.com/the-voodoo-brand/image/upload/v1627420671/PNG_JPG/slider/web/Slider_6_ws7tc9.png" atl={welcome}/>
                    </picture>

                </Slider>
            </div>
        </div>
    )
}
