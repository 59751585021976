import React, {useEffect, useState} from "react";
import './App.scss';
import {Route} from 'react-router-dom';
import Routes from "./Routes";
import NavigationBar from "./components/Navbar/Navbar";
import {CartProvider} from "./components/Context/CartContext";
import DetailProductPage from "./pages/DetailProductPage/DetailProductPage";
import CartComponent from "./pages/CartPage/CartComponent";
import Footer from "./components/Footer/Footer";


function App() {

    // useEffect(() => {
    //     if (localStorage.getItem('paid')) {
    //         localStorage.removeItem('cart')
    //     }
    // }, [])

    let cart = JSON.parse(localStorage.getItem("cart"));
    let value = 0;

    if (cart) {
        for (let element of cart) {

            value += element.quantity;
        }
    }

    const [countCart, setCountCart] = useState(value);

    return (
        <div>
            <CartProvider value={{countCart, setCountCart}}>
                <NavigationBar countCart={countCart}/>
                <Routes setCountCart={setCountCart}>
                    <Route path="/playground/team/:name/:surname" component={DetailProductPage}/>
                    <Route path="/cart" component={CartComponent}/>
                </Routes>
            </CartProvider>
        </div>

    );
}

export default App;
