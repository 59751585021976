import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import "./Playground.scss";
import Footer from "../../components/Footer/Footer";

export default function Playground() {

    const history = useHistory();

    const toTribe = () => {
        history.push('/playground/tribe')
    }

    const toFamily = () => {
        history.push('/playground/family')
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="playground">
                <div onClick={toFamily} id="family" className="playground__cat">
                    <h1>FAMILY</h1>
                </div>
                <div onClick={toTribe} id="tribe" className="playground__cat tribe">
                    <h1>TRIBE</h1>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
