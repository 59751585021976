import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {API, PATH} from "../../../shared/services/api";
import Footer from "../../../components/Footer/Footer";
import {useTranslation} from "react-i18next";

export default function SurfboardsPage() {

    const [t, i18n] = useTranslation("global");

    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [surfboards, setSurfboards] = useState([]);
    const width = window.innerWidth;

    useEffect(() => {
            API.get('product/surfboards').then(res =>
                setSurfboards(res.data)
            )
        },
        [])

    return (
        <div className="product__img__gallery-container">
            <p className="product__img__black-text center">{t('products.surf-col')}</p>
            <div className="col-12 row px-0 mx-0 product__img__big-container">

                {/* SURFBOARDS loop */}

                {surfboards.map((surfboard, i) =>
                    <div key={surfboard._id} className="col-12 col-md-6 col-xl-3 product__img__container">
                        {width < 550 &&
                        <Link to={PATH.SURFBOARDS +'/' + surfboard.title} className="product__img__link">
                            <picture>
                                <source type="image/webp" srcSet={surfboard.frontPicture.phone} alt={surfboard.title} className="product__img__gallery-section" />
                                <img  className="product__img__gallery-section"  src={surfboard.frontPictureJPG.phone} alt={surfboard.title}/>
                            </picture>

                        </Link>
                        }
                        {width > 550 &&
                        <Link to={PATH.SURFBOARDS + '/' + surfboard.title} className="product__img__link">
                            <picture>
                                <source type="image/webp" srcSet={surfboard.frontPicture.tablet} alt={surfboard.title} className="product__img__gallery-section" />
                                <img  className="product__img__gallery-section"  src={surfboard.frontPictureJPG.tablet} alt={surfboard.title}/>
                            </picture>

                        </Link>

                        }
                        <p className="product__img__black-text custom-typo">
                            {surfboard.title !== 'Eleanor' &&
                                <span>THE </span>
                            }
                            {surfboard.title}</p>
                        <p className="product__img__price">{surfboard.price} €</p>
                    </div>
                )}
            </div>
            <Footer/>
        </div>

    )
}
