import React, {useEffect, useContext,useState, Suspense, lazy} from "react";
import {Link} from "react-router-dom";
import {useParams} from "react-router-dom";
import {API} from "../../shared/services/api";
import "./DetailProductPage.scss";
import Slider from "react-slick";
import {Accordion, Card} from "react-bootstrap";
import {GrClose, IoIosArrowDown, MdCheck} from "react-icons/all";
import $ from "jquery";
import {FaShoppingCart} from "react-icons/all";
import Modal from "react-modal";
import {CartContext} from "../../components/Context/CartContext";
import {useTranslation} from "react-i18next";

const Footer = lazy(() => import('../../components/Footer/Footer'));

export default function DetailProductPage() {


    const {setCountCart} = useContext(CartContext);

    // jQuery for add to cart button
    (function() {
        var removeSuccess;

        removeSuccess = function() {
            return $('.button').removeClass('success');
        };

        $(document).ready(function() {
            return $('.button').click(function() {
                $(this).addClass('success');
                return setTimeout(removeSuccess, 2000);
            });
        });

    }).call(this);

    const title = useParams().title;
    const color = useParams().color;
    const width = window.innerWidth;

    let settings = {
        dots: true,
        infinite: false,
        speed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        adaptiveHeight: true
    };

    const [product, setProduct] = useState(null);
    const [productModel, setProductModel] = useState([]);
    const [chosenSrc, setChosenSrc] = useState(null);
    const [t, i18n] = useTranslation("global");

    const [modalIsOpen, setModalIsOpen] = useState(false);

    //petitions for products and product by color/title
    useEffect(() => {
        API.get('product/' + title + '/' + color).then(res => {
            //console.log(res.data)
            setProduct(res.data);
            if (product !== null) {
                setChosenSrc(product[0].frontPictureJPG.tablet)
            }
        })
        // petitions for all products

        API.get('product/' + title).then(res => {
            setProductModel(res.data);
        })
        localCart = JSON.parse(localCart);
            if(localCart) setCart(localCart)

    }, []);

    const modalPicture = () => {
        if (chosenSrc == null) {
            if (product[0].frontPictureJPG.web === ([] || '' || null)) {
                setChosenSrc(product[0].frontPictureJPG.tablet)
            } else {
                setChosenSrc(product[0].frontPictureJPG.web)
            }
        } else if (chosenSrc === product[0].frontPictureJPG.tablet && product[0].frontPictureJPG.web !== null) {
            setChosenSrc(product[0].frontPictureJPG.web);
        }
        setModalIsOpen(true);
    }

    function handleCloseModal() {
        setModalIsOpen(false);
    }

    const showImageBack = () => {
        if (product !== null) {
            setChosenSrc(product[0].backPictureJPG.tablet)
        }
    }

    const showImageFront = () => {
        if (product !== null) {
            setChosenSrc(product[0].frontPictureJPG.tablet)
        }
    }

    const showExtra1 = () => {
        if (product !== null) {
            setChosenSrc(product[0].additionalPictures[0])
        }
    }

    const showExtra2 = () => {
        if (product !== null) {
            setChosenSrc(product[0].additionalPictures[1])
        }
    }

    const showExtra3 = () => {
        if (product !== null) {
            setChosenSrc(product[0].additionalPictures[2])
        }
    }

    const showExtra4 = () => {
        if (product !== null) {
            setChosenSrc(product[0].additionalPictures[3])
        }
    }

    // shopping cart

    let [cart, setCart] = useState([]);
    let localCart = localStorage.getItem("cart");
    //when user check out i have to set localStorage.removeItem(“cart”)
    
    // Método para añadir elementos al carrito
    const addItem = (item) => {

        
        let select = document.getElementById('selector');

        // Crear un objeto con la info del elemento que se quiere añadir
        let info = {
            id: item._id,
            productCode: item.productCode,
            gender: item.gender,
            color: item.color,
            title: item.title,
            price: item.price,
            size: select.value,
            frontPicture: item.frontPictureJPG.phone,
            quantity: 1,
            type: item.type,
        }
        let listItem = [info];
      let recoveredData = localStorage.getItem("cart");

      //Si no hay nada en el localstorage "Cart" que se cree con el elemento seleccionado
      if(recoveredData == null){

        localStorage.setItem("cart", JSON.stringify(listItem));

        //Si ya existe el localstorage Cart
      } else {
          // Recuperar lo que hay el "cart". Buscar si el elemento que se quiere introducir ya existe y su indice.
          let data = JSON.parse(recoveredData);
         
          
          const existentItem = data.find((element) => element.id === info.id && element.color === info.color && element.size === info.size);
          const indexItem = data.findIndex((element) => element.id === info.id && element.color === info.color && element.size === info.size);

         
          // Si no hay ningun elemento en el cart igual al elemento que se quiere introducir se añade. 
          if(existentItem === undefined){

            data.push(info);

            // If there's already an exact element as the one we are introducing, modify property quantity
          }else{
              data[indexItem].quantity +=1;
          }


          // //Update the cart
       
        localStorage.setItem("cart", JSON.stringify(data));

      }

      // Cambiar la variable del contexto para que en el nav aparezcan el numero de elementos que hay en carrito. 
        let value = 0;
        let cart = JSON.parse(localStorage.getItem("cart"));
        for(let element of cart){

            value += element.quantity;
        }
        setCountCart(value);
    }
   
    return (
        <div>
            <div className="row col-12 mx-0 detail">

                {/* view for tablets and phones */}
                {product && product[0].frontPictureJPG && width < 992 &&
                <div className="col-12 px-0 detail__slider-container">
                    <Slider {...settings}>
                        <img className="detail__picture" src={product[0].frontPictureJPG.phone} alt={product[0].title}/>
                        {product && product[0].backPictureJPG &&
                        <img className="detail__picture" src={product[0].backPictureJPG.phone} alt={product[0].title}/>
                        }
                        {product && product[0].additionalPictures && product[0].additionalPictures[0] &&
                        <img className="detail__picture-thumb" src={product[0].additionalPictures[0]} alt={product[0].title}/>
                        }
                        {product && product[0].additionalPictures && product[0].additionalPictures[1] &&
                        <img className="detail__picture-thumb" src={product[0].additionalPictures[1]} alt={product[0].title}/>
                        }
                        {product && product[0].additionalPictures && product[0].additionalPictures[2] &&
                        <img className="detail__picture-thumb" src={product[0].additionalPictures[2]} alt={product[0].title}/>
                        }
                    </Slider>
                </div>
                }

                {/* view for pc and big screens */}

                {product && product[0].frontPictureJPG && width > 992 &&
                <div className="col-12 col-lg-8 row detail__pics-container">
                    <div id="thumbnail-scroll" className="col-2 detail__thumbnail-container">
                        <img id="frontPic" className="detail__picture-thumb" onClick={showImageFront}
                             src={product[0].frontPictureJPG.phone} alt={product[0].title}/>
                        {product && product[0].backPictureJPG &&
                        <img id="backPic" className="detail__picture-thumb" onClick={showImageBack}
                             src={product[0].backPictureJPG.phone} alt={product[0].title}/>
                        }

                        {/* additional pictures displayed */}

                        {product && product[0].additionalPictures && product[0].additionalPictures[0] &&
                        <img id="extra1" className="detail__picture-thumb" onClick={showExtra1}
                             src={product[0].additionalPictures[0]} alt={product[0].title}/>
                        }
                        {product && product[0].additionalPictures && product[0].additionalPictures[1] &&
                        <img id="extra2" className="detail__picture-thumb" onClick={showExtra2}
                             src={product[0].additionalPictures[1]} alt={product[0].title}/>
                        }
                        {product && product[0].additionalPictures && product[0].additionalPictures[2] &&
                        <img id="extra3" className="detail__picture-thumb" onClick={showExtra3}
                             src={product[0].additionalPictures[2]} alt={product[0].title}/>
                        }
                        {product && product[0].additionalPictures && product[0].additionalPictures[3] &&
                        <img id="extra4" className="detail__picture-thumb" onClick={showExtra4}
                             src={product[0].additionalPictures[3]} alt={product[0].title}/>
                        }
                    </div>
                    <div className="col-9 detail__main-container">
                        {chosenSrc !== null &&
                        <Suspense fallback={<p>Product</p>}>
                            <img onClick={() => setModalIsOpen(true)} className="detail__picture" src={chosenSrc}
                                 alt={product[0].name}/>
                        </Suspense>

                        }
                        {chosenSrc == null &&
                        <img onClick={modalPicture} className="detail__picture"
                             src={product[0].frontPictureJPG.tablet} alt={product[0].title}/>
                        }
                    </div>

                    <Modal id="product-amplifier" isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
                        {chosenSrc !== null &&
                        <img className="detail__modal__picture" src={chosenSrc} alt={product[0].title}/>
                        }
                        <button className="sidebar__close amplifier" onClick={() => setModalIsOpen(false)}>
                            <GrClose className="close"/>
                        </button>

                    </Modal>

                </div>
                }
                {product && productModel &&
                <div className="col-12 col-lg-3 detail__info-container">

                    <p className="detail__product-title">{title}</p>

                    <p className="detail__product-desc__price">{product[0].price} €</p>

                    {/* show color depending on DDBB color */}
                    {product[0].color === 'Black' &&
                    <p className="detail__product-desc">Color: {t('products.colors.black')}</p>
                    }

                    {product[0].color === 'Grey' &&
                    <p className="detail__product-desc">Color: {t('products.colors.grey')}</p>
                    }

                    {product[0].color === 'Yellow' &&
                    <p className="detail__product-desc">Color: {t('products.colors.yellow')}</p>
                    }

                    {product[0].color === 'White' &&
                    <p className="detail__product-desc">Color: {t('products.colors.white')}</p>
                    }

                    {product[0].color === 'Off White' &&
                    <p className="detail__product-desc">Color: {t('products.colors.off-white')}</p>
                    }

                    {product[0].color === 'Pink' &&
                    <p className="detail__product-desc">Color: {t('products.colors.pink')}</p>
                    }

                    {product[0].color === 'Khaki' &&
                    <p className="detail__product-desc">Color: {t('products.colors.khaki')}</p>
                    }

                    {product[0].color === 'Black and White' &&
                    <p className="detail__product-desc">Color: {t('products.colors.black-white')}</p>
                    }

                    <div className="row px-3">
                        <div className="detail__circle-container">
                            {productModel.map((prod, i) =>
                                <Link to={"/wardrobe/" + prod.title + "/" + prod.color}>
                                    {/* selected only when product color circle == url */}
                                    <div onClick={"to" + prod.color + "Product"} className={` ${prod.color}`}></div>
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="detail__product-desc-container column px-0">
                        <p className="detail__product-desc">{t('products.size')}:</p>
                        {product[0].availableSizes && (product[0].availableSizes.S > 0 || product[0].availableSizes.M > 0 || product[0].availableSizes.L > 0 || product[0].availableSizes.XL > 0 || product[0].availableSizes.XXL > 0  || product[0].availableSizes.uniqueSize > 0) &&
                        <select className="row mx-0 px-3 py-2" id="selector">
                            {product[0].availableSizes.S && product[0].availableSizes.S > 0 &&
                            <option value="S">S</option>
                            }
                            {product[0].availableSizes.M && product[0].availableSizes.M > 0 &&
                            <option value="M">M</option>
                            }
                            {product[0].availableSizes.L && product[0].availableSizes.L > 0 &&
                            <option value="L">L</option>
                            }
                            {product[0].availableSizes.XL && product[0].availableSizes.XL > 0 &&
                            <option value="XL">XL</option>
                            }
                            {product[0].availableSizes.XXL && product[0].availableSizes.XXL > 0 &&
                            <option value="XXL">XXL</option>
                            }
                            {product[0].availableSizes.uniqueSize && product[0].availableSizes.uniqueSize > 0 &&
                            <option value="Unique">{t('products.unique-size')}</option>
                            }
                        </select>
                        }
                    </div>
                    {product[0].availableSizes.S < 1 && product[0].availableSizes.M < 1 && product[0].availableSizes.L < 1 && product[0].availableSizes.XL < 1 && product[0].availableSizes.XXL < 1  && product[0].availableSizes.uniqueSize < 1 &&
                    <h3 className="detail__product-sold">sold out</h3>
                    }

                    {product[0].availableSizes && (product[0].availableSizes.S > 0 || product[0].availableSizes.M > 0 || product[0].availableSizes.L > 0 || product[0].availableSizes.XL > 0 || product[0].availableSizes.XXL > 0 || product[0].availableSizes.uniqueSize > 0) &&

                    <button onClick={() => addItem(product[0])} className="button" role="button">
                        <span>{t('products.add-to-cart')}</span>
                        <div className="icon">
                            <FaShoppingCart className="fa-remove shop-icon-cart"/>
                            <MdCheck className="fa-check shop-icon"/>
                        </div>
                    </button>

                    }

                    <Accordion className="detail__product__accordion">
                        {product[0].description &&
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                <div className="row justify-content-between">
                                    <span>{t('products.description')}</span>
                                    <span><IoIosArrowDown/></span>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                {/*<Card.Body>{t('products.descriptions.' + product[0].title)}</Card.Body>*/}
                                <Card.Body>{product[0].description}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        }
                        {product[0].composition &&
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                <div className="row justify-content-between">
                                    <span>{t('products.composition')}</span>
                                    <span><IoIosArrowDown/></span>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    {product && product[0].composition && product[0].composition.percentage &&
                                    <p>{product[0].composition.percentage}</p>
                                    }
                                    {product && product[0].composition && product[0].composition.grams &&
                                    <span>{product[0].composition.grams}</span>
                                    }
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        }
                        {product[0].careInfo &&
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                                <div className="row justify-content-between">
                                    <span>{t('products.care-info')}</span>
                                    <span><IoIosArrowDown/></span>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>{product[0].careInfo}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        }
                    </Accordion>
                </div>
                }
            </div>
            <Suspense fallback={<p>The Voodoo Brand...</p>}>
                <Footer/>
            </Suspense>
        </div>
    )
}
