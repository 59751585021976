import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import './CustomerForm.scss';
import {PATH} from "../../shared/services/api";

export default function CustomerForm() {

    const {register, handleSubmit, errors} = useForm();
    const [chosen, setChosen] = useState('España (Península y Baleares)');


    const updateCountry = (chosenCountry) => {
        setChosen(chosenCountry);
    }

    const europe = [
        'Albania',
        'Alemania',
        'Andorra',
        'Armenia',
        'Austria',
        'Azerbaiyan',
        'Belgica',
        'Bielorrusia',
        'Bosnia y Herzegovina',
        'Bulgaria',
        'Chipre',
        'Ciudad del Vaticano',
        'Croacia',
        'Dinamarca',
        'Eslovaquia',
        'Eslovenia',
        'Estonia',
        'Finlandia',
        'Francia',
        'Georgia',
        'Grecia',
        'Hungria',
        'Irlanda',
        'Islandia',
        'Italia',
        'Kazajistan',
        'Letonia',
        'Liechtenstein',
        'Lituania',
        'Luxemburgo',
        'Macedonia del Norte',
        'Malta',
        'Moldavia',
        'Monaco',
        'Montenegro',
        'Noruega',
        'Países Bajos',
        'Polonia',
        'Reino Unido',
        'República Checa',
        'Rumania',
        'Rusia',
        'San Marino',
        'Serbia',
        'Suecia',
        'Suiza',
        'Turquia',
        'Ucrania'
    ]

    const spainPortugal = [
        'España (Península y Baleares)',
        'España (Islas Canarias, Ceuta y Melilla)',
        'Portugal',
    ]

    const text = {
        continue: 'Continue to shipping'
    }

    const onSubmit = formData => {
        console.log('form data being submited');
        // if there's (for instance) a checkbox name Ceuta, then update special city
        setData(formData);
    }

    // Storing in the localstorage the data of the user, to later pass it to the back
    const setData = (formData) => {
        //first erase localstorage customer if there's one.
        let customerInfo = {
            email: formData.email,
            name: formData.name,
            surname: formData.surname,
            street: formData.street,
            address_extra: formData.address_extra,
            country: chosen,
            postalCode: formData.postalCode,
            phone: formData.phone,
            city: formData.city
        }
        if (localStorage.getItem('customer')) {
            localStorage.removeItem('customer');
        }
        localStorage.setItem("customer", JSON.stringify(customerInfo));
        console.log('first data set' + localStorage.getItem('customer'))

        redirectToCheckout();

    }

    const redirectToCheckout = () => {
        window.location.assign(PATH.PAYMENT_CHECKOUT)
    }

    return (
        <div className="customer-form">
            <form id="customer-info-form" className="col-12" onSubmit={handleSubmit(onSubmit)}>

                <label className="" htmlFor="email">E-mail*</label>
                <input className="customer-form__input" type="text" name="email" placeholder="example@gmail.com"
                       ref={register({required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/})}/>
                {errors.email && <span className="customer-form__warning"> Review e-mail field</span>}

                <label className="" htmlFor="phone">Contact phone*</label>
                <input className="customer-form__input" type="text" name="phone" placeholder="+34654321000"
                       ref={register({required: true, pattern: /^([+\d].*)?\d$/})}/>
                {errors.phone && <span className="customer-form__warning"> Only numbers or + sign are allowed</span>}

                <div className="row mx-0">
                    <div className="customer-form__input__half-container">
                        <label className="" htmlFor="name">Name*</label>
                        <input className="customer-form__input" type="text" name="name"
                               ref={register({required: true, pattern: /^[-'a-zA-ZÀ-ÖØ-öø-ÿ_ ]+$/})}/>
                    </div>
                    <div className="customer-form__input__half-container">
                        <label className="" htmlFor="surname">Surname/s*</label>
                        <input className="customer-form__input" type="text" name="surname"
                               ref={register({required: true, pattern: /^[-'a-zA-ZÀ-ÖØ-öø-ÿ_ ]+$/})}/>
                    </div>
                    {errors.name && <span className="customer-form__warning"> Introduce a valid name </span>}
                    {errors.surname && <span className="customer-form__warning"> Introduce a valid surname </span>}
                </div>
                <div className="row mx-0">
                    <div className="customer-form__input__half-container">
                        <label className="" htmlFor="street">Street and number*</label>
                        <input className="customer-form__input" type="text" name="street"
                               ref={register({required: true})}/>
                        {errors.address && <span className="customer-form__warning"> Review your address </span>}
                    </div>
                    <div className="customer-form__input__half-container">
                        <label className="" htmlFor="postalCode">Postal code*</label>
                        <input className="customer-form__input" type="text" name="postalCode"
                               ref={register({required: true, pattern: /^[0-9]*$/})}/>
                        {errors.postalCode &&
                        <span className="customer-form__warning"> Introduce a numeric postal code </span>}
                    </div>
                </div>

                <label className="" htmlFor="address_extra">Apartment, local, etc</label>
                <input className="customer-form__input" type="text" name="address_extra"
                       ref={register({required: false})}/>
                {errors.address_extra && <span className="customer-form__warning"> Review your address </span>}

                <label className="" htmlFor="city">City and/or Province*</label>
                <input className="customer-form__input" type="text" name="city"
                       ref={register({required: true, pattern: /^[-'a-zA-ZÀ-ÖØ-öø-ÿ_ ]+$/})}/>
                {errors.city && <span className="customer-form__warning"> Introduce a city </span>}

                <label className="" htmlFor="country">Country/Region*</label>
                <select onChange={() => updateCountry(document.querySelector('#country').value)} defaultValue='Country'
                        className="customer-form__input" name="country" id="country" ref={register({required: true})}>
                    {spainPortugal.map((country, i) =>
                        <option value={country}>{country}</option>
                    )}
                    {europe.map((european, i) =>
                        <option value={european}>{european}</option>
                    )}
                </select>

                <input className="login__button shippings" type="submit" value={text.continue}/>
            </form>
        </div>
    )
}
