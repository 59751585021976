import React, {useEffect, useState} from "react";
import "./PaymentPage.scss";
import CustomerForm from "../../components/CustomerForm/CustomerForm";

export default function PaymentPage() {

    const [cart, setCart] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        let cart = JSON.parse(localStorage.getItem("cart"));
        setCart(cart);
        let total = 0;
        for (let i = 0; i < cart.length; i++) {
            total += (cart[i].quantity * cart[i].price);
            console.log(total);
            setTotalPrice(total);
        }

    }, [])


    return (
        <div className="row px-0 mx-0 col-12">
                <div className="col-12 col-sm-6 px-0 payment__phases">
                    <div className="payment__left-container">
                        <h2 className="paymment__title">CHECKOUT INFORMATION</h2>
                        <CustomerForm/>
                    </div>
                </div>
                <div className="col-12 col-sm-6 px-0 payment__pricing">
                    <h2 className="payment__title">YOUR PRODUCTS</h2>
                    {cart.map((item, i) =>
                        <div className="payment__product-container">
                            <img className="payment__product-thumbnail" src={item.frontPicture} alt=""/>
                            <div className="payment__prices-container">
                                <p className="align-self-end">{item.title} x {item.quantity}</p>
                                <p className="align-self-end">{item.size}{item.surfSize}</p>
                                <p className="align-self-end">Unit price: {item.price} €</p>
                            </div>
                        </div>
                    )}
                    <div className="payment__product-container prices">
                        <span>Subtotal:</span><span className="montserrat">{totalPrice} €</span>
                    </div>
                    <div className="payment__product-container">
                        <span>Shippings:</span>
                        <span className="montserrat">Calculated in the next step</span>
                    </div>
                    <div className="payment__product-container">
                        <h3>Total:</h3><h3>{totalPrice} €</h3>
                    </div>
                </div>
        </div>

    );
}
