import React, {useEffect, useState} from "react";
import {API} from "../../../shared/services/api";
import Footer from "../../../components/Footer/Footer";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function SweatWear() {

    const [sweatwear, setSweatwear] = useState(null);
    const [hoodies, setHoodies] = useState(null);
    const [t, i18n] = useTranslation("global");
    const width = window.innerWidth;

    useEffect(() => {
            API.get('product/sweatshirts').then(res =>
                setSweatwear(res.data)
            )
            API.get('product/hoodies').then(res =>
                setHoodies(res.data)
            )
        },
        [])

    return (
        <div className="product__img__gallery-container">

            {/* ------------------------------- pc view ------------------------------------- */}


            {width > 767 &&

            <div>

                <p className="product__img__black-text center">{t('products.sweat-hood-col')}</p>

                {sweatwear && hoodies &&

                <div className="col-12 row px-0 mx-0 product__img__big-container">

                    {/* first product */}
                    <div className="col-12 col-md-6 col-xl-3 product__img__container">
                        <Link to={"/wardrobe/" + sweatwear[2].title + '/' + sweatwear[2].color}
                              className="product__img__link">

                            <picture>
                                <source type="image/webp" srcSet={sweatwear[2].frontPicture.tablet}  className="product__img__gallery-section" alt={sweatwear[2].title}/>
                                <img  className="product__img__gallery-section" src={sweatwear[2].frontPictureJPG.tablet} alt={sweatwear[2].title}/>
                            </picture>


                        </Link>
                        <p className="product__img__black-text custom-typo">{sweatwear[2].title}</p>
                        <p className="product__img__price">{sweatwear[2].price} €</p>
                        <div className="product__img__balls">
                            <div className={sweatwear[0].color}></div>
                            <div className={sweatwear[1].color}></div>
                            <div className={sweatwear[2].color}></div>
                        </div>
                    </div>

                    {/* second product */}
                    <div className="col-12 col-md-6 col-xl-3 product__img__container">
                        <Link to={"/wardrobe/" + sweatwear[3].title + '/' + sweatwear[3].color}
                              className="product__img__link">

                            <picture>
                                <source type="image/webp" srcSet={sweatwear[3].frontPicture.tablet}  className="product__img__gallery-section" alt={sweatwear[3].title}/>
                                <img  className="product__img__gallery-section" src={sweatwear[3].frontPictureJPG.tablet} alt={sweatwear[3].title}/>
                            </picture>

                        </Link>
                        <p className="product__img__black-text custom-typo">{sweatwear[3].title}</p>
                        <p className="product__img__price">{sweatwear[3].price} €</p>
                        <div className="product__img__balls">
                            <div className={sweatwear[3].color}></div>
                        </div>
                    </div>

                    {/* third product */}
                    <div className="col-12 col-md-6 col-xl-3 product__img__container">
                        <Link to={"/wardrobe/" + sweatwear[4].title + '/' + sweatwear[4].color}
                              className="product__img__link">

                            <picture>
                                <source type="image/webp" srcSet={sweatwear[4].frontPicture.tablet}  className="product__img__gallery-section" alt={sweatwear[4].title}/>
                                <img  className="product__img__gallery-section" src={sweatwear[4].frontPictureJPG.tablet} alt={sweatwear[4].title}/>
                            </picture>

                        </Link>
                        <p className="product__img__black-text custom-typo">{sweatwear[4].title}</p>
                        <p className="product__img__price">{sweatwear[4].price} €</p>
                        <div className="product__img__balls">
                            <div className={sweatwear[4].color}></div>
                        </div>
                    </div>

                    {/* fourth product */}

                    <div className="col-12 col-md-6 col-xl-3 product__img__container">

                        <Link to={"/wardrobe/" + hoodies[1].title + '/' + hoodies[1].color}
                              className="product__img__link">

                            <picture>
                                <source type="image/webp" srcSet={hoodies[1].frontPicture.tablet}  className="product__img__gallery-section" alt={hoodies[1].title}/>
                                <img  className="product__img__gallery-section" src={hoodies[1].frontPictureJPG.tablet} alt={hoodies[1].title}/>
                            </picture>

                        </Link>
                        <p className="product__img__black-text custom-typo">{hoodies[1].title}</p>
                        <p className="product__img__price">{hoodies[1].price} €</p>
                        <div className="product__img__balls">
                            <div className={hoodies[0].color}></div>
                            <div className={hoodies[1].color}></div>
                            <div className={hoodies[2].color}></div>
                            <div className={hoodies[3].color}></div>

                        </div>
                    </div>

                </div>

                }
            </div>
            }



            {/* ---------- view for tablets and phones --------------- */}

            {width < 767 && sweatwear && hoodies &&

            <div>
                <p className="product__img__black-text center">{t('products.sweat-col')}</p>

                <div className="col-12 row px-0 mx-0 product__img__big-container">

                    {/* first product */}
                    <div className="col-12 col-md-6 col-xl-3 product__img__container">
                        <Link to={"/wardrobe/" + sweatwear[2].title + '/' + sweatwear[2].color}
                              className="product__img__link">

                            <picture>
                                <source type="image/webp" srcSet={sweatwear[2].frontPicture.tablet}  className="product__img__gallery-section" alt={sweatwear[2].title}/>
                                <img  className="product__img__gallery-section" src={sweatwear[2].frontPictureJPG.tablet} alt={sweatwear[2].title}/>
                            </picture>

                        </Link>
                        <p className="product__img__black-text custom-typo">{sweatwear[2].title}</p>
                        <p className="product__img__price">{sweatwear[2].price} €</p>
                        <div className="product__img__balls">
                            <div className={sweatwear[0].color}></div>
                            <div className={sweatwear[1].color}></div>
                            <div className={sweatwear[2].color}></div>
                        </div>
                    </div>

                    {/* second product */}
                    <div className="col-12 col-md-6 col-xl-3 product__img__container">
                        <Link to={"/wardrobe/" + sweatwear[3].title + '/' + sweatwear[3].color}
                              className="product__img__link">

                            <picture>
                                <source type="image/webp" srcSet={sweatwear[3].frontPicture.tablet}  className="product__img__gallery-section" alt={sweatwear[3].title}/>
                                <img  className="product__img__gallery-section" src={sweatwear[3].frontPictureJPG.tablet} alt={sweatwear[3].title}/>
                            </picture>

                        </Link>
                        <p className="product__img__black-text custom-typo">{sweatwear[3].title}</p>
                        <p className="product__img__price">{sweatwear[3].price} €</p>
                        <div className="product__img__balls">
                            <div className={sweatwear[3].color}></div>
                        </div>
                    </div>

                    {/* third product */}
                    <div className="col-12 col-md-6 col-xl-3 product__img__container">
                        <Link to={"/wardrobe/" + sweatwear[4].title + '/' + sweatwear[4].color}
                              className="product__img__link">

                            <picture>
                                <source type="image/webp" srcSet={sweatwear[4].frontPicture.tablet}  className="product__img__gallery-section" alt={sweatwear[4].title}/>
                                <img  className="product__img__gallery-section" src={sweatwear[4].frontPictureJPG.tablet} alt={sweatwear[4].title}/>
                            </picture>

                        </Link>
                        <p className="product__img__black-text custom-typo">{sweatwear[4].title}</p>
                        <p className="product__img__price">{sweatwear[4].price} €</p>
                        <div className="product__img__balls">
                            <div className={sweatwear[4].color}></div>
                        </div>
                    </div>

                </div>

                <p className="product__img__black-text center">{t('products.hoodies-col')}</p>

                <div className="col-12 row px-0 mx-0 product__img__big-container">
                    {/* fourth product */}

                    <div className="col-12 col-md-6 col-xl-3 product__img__container">
                        <Link to={"/wardrobe/" + hoodies[1].title + '/' + hoodies[1].color}
                              className="product__img__link">

                            <picture>
                                <source type="image/webp" srcSet={hoodies[1].frontPicture.tablet}  className="product__img__gallery-section" alt={hoodies[1].title}/>
                                <img  className="product__img__gallery-section" src={hoodies[2].frontPictureJPG.tablet} alt={hoodies[1].title}/>
                            </picture>

                        </Link>
                        <p className="product__img__black-text custom-typo">{hoodies[1].title}</p>
                        <p className="product__img__price">{hoodies[1].price} €</p>
                        <div className="product__img__balls">
                            <div className={hoodies[0].color}></div>
                            <div className={hoodies[1].color}></div>
                            <div className={hoodies[2].color}></div>
                            <div className={hoodies[3].color}></div>

                        </div>
                    </div>
                </div>


            </div>
            }


            <Footer/>
        </div>
    )
}
