import axios from "axios";

export const BACK_URL = process.env.REACT_APP_BACK_URL;
export const LOCALHOST = 'http://localhost:4000/';

export const API = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
    timeout:6000,
    headers:{

        Accept: "application/json",
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
        'Authorization':  `Bearer ${localStorage.getItem("token")}`
    }
});

export const APIimage = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
    timeout:6000,
    headers:{

        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        //"Access-Control-Allow-Origin": "*",
        'Authorization':  `Bearer ${localStorage.getItem("token")}`
    }
});

// VARIABLES for EMAIL JS contact form

export const TEMPLATE_ID = 'template_6s6rbcs'
export const USER_ID = 'user_YkT93r0qoPwpsWMXuQeo9q'

export const PATH = {
    CART: '/cart',
    CONTACT: '/contact',
    LOGIN: '/adm1n/log1n',
    ADMIN: '/felix/admin',
    STOCK: '/felix/stock',
    SURFBOARDS: '/quiver',
    WARDROBE: '/wardrobe',
    WARDROBE_SOCKS: '/wardrobe/socks',
    WARDROBE_SHIRTS: '/wardrobe/tshirts',
    WARDROBE_SWEAT: '/wardrobe/sweatwear',
    WARDROBE_CAPS: '/wardrobe/headwear',
    WARDROBE_WETSUITS: '/wardrobe/wetsuits',
    STORES: '/stores',
    NEW_PRODUCT: '/felix/admin/new',
    PAYMENT_CHECKOUT: '/payment/checkout',
    PAYMENT_CORRECT: '/payment/correct',
    PAYMENT: '/payment',
    SHIPPING_INFO: '/shipping',
    TERMS_COND: '/terms-conditions',
    RETURNS: '/returns',
    ABOUT_US: '/about',
    PLAYGROUND: '/playground',
    PLAYGROUND_TRIBE: '/playground/tribe',
    PLAYGROUND_FAMILY: '/playground/family',
    HOME: '/',
}
