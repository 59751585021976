import React, {useEffect, useState} from "react";
import "./StockPage.scss"
import {API} from "../../shared/services/api";
import {useForm} from "react-hook-form";
import {FcPlus} from "react-icons/all";
import NewProduct from "../../components/NewProduct/NewProduct";

export default function StockPage() {

    const {register, handleSubmit} = useForm();
    const [products, setProducts] = useState([]);
    const [edit, setEdit] = useState(true);
    const [productId, setProductId] = useState('');
    const [productToBeUpdated, setProductToBeUpdated] = useState('');
    const [newForm, setNewForm] = useState(false);

    useEffect(() => {
        API.get('product/all').then(res => {
            console.log(res.data)
            setProducts(res.data)
        }).catch(err => console.log(err))
    }, [])


    const editStock = (productId) => {

        API.get('product/productId/' + productId).then(res => {
            console.log(res.data.product)
            setProductToBeUpdated(res.data.product)
        })

        setProductId(productId)
        setEdit(false)
    }

    const cancelEdit = () => {
        setProductId('');
        setEdit(true)
    }

    const onSubmit = formData => {

        console.log(formData)

        if (productToBeUpdated) {

            let finalUpdate = {
                additionalPictures: productToBeUpdated.additionalPictures,
                availableSizes: {
                    S: formData.s ? parseInt(formData.s) : parseInt(productToBeUpdated.availableSizes.s),
                    M: formData.m ? parseInt(formData.m) : parseInt(productToBeUpdated.availableSizes.m),
                    L: formData.l ? parseInt(formData.l) : parseInt(productToBeUpdated.availableSizes.l),
                    XL: formData.xl ? parseInt(formData.xl) : parseInt(productToBeUpdated.availableSizes.xl),
                    XXL: formData.xxl ? parseInt(formData.xxl) : parseInt(productToBeUpdated.availableSizes.xxl),
                    uniqueSize: formData.uniquesize ? parseInt(formData.uniquesize) : parseInt(productToBeUpdated.availableSizes.uniqueSize)
                }
                , //update this,
                backPicture: productToBeUpdated.backPicture ? productToBeUpdated.backPicture : '',
                color: productToBeUpdated.color ? productToBeUpdated.color : '',
                description: formData.description ? formData.description : '',
                frontPicture: productToBeUpdated.frontPicture,
                gender: productToBeUpdated.gender ? productToBeUpdated.gender : 'Unisex',
                price: formData.price ? parseInt(formData.price) : parseInt(productToBeUpdated.price), //update this
                productCode: productToBeUpdated.productCode,
                title: productToBeUpdated.title,
                type: productToBeUpdated.type,
                surfSizes: productToBeUpdated.surfSizes ? productToBeUpdated.surfSizes : '',
                composition: productToBeUpdated.composition ? productToBeUpdated.composition : '',
                careInfo: productToBeUpdated.careInfo ? productToBeUpdated.careInfo : '',
                frontPictureJPG: productToBeUpdated.frontPictureJPG ? productToBeUpdated.frontPictureJPG : '',
                backPictureJPG: productToBeUpdated.backPictureJPG ? productToBeUpdated.backPictureJPG : '',
                additionalPicturesJPG: productToBeUpdated.additionalPicturesJPG ? productToBeUpdated.additionalPicturesJPG : ''
            }

            console.log('finalUpdate is: ' + JSON.stringify(finalUpdate))

            // here PUT method of product
            API.put('product/update/' + productToBeUpdated._id, finalUpdate).then(res => {
                console.log(res.data)
                alert ('Producto correctamente actualizado, para ver los cambios POR FAVOR ACTUALICE LA PÁGINA')
            })


            setProductId('');
            setEdit(true)
        }
    }


    return (
        <div className="stock">
            <h3>STOCK</h3>
            {!newForm &&
                <button onClick={() => setNewForm(true)} type="button" className="btn btn-secondary btn-lg my-3">
                    <FcPlus/> ADD NEW PRODUCT</button>
            }
            { newForm &&
                <div>
                    <NewProduct/>
                    <button className="btn btn-danger my-2" onClick={() => setNewForm(false)}> cancel</button>
                </div>
            }

            <div className="col-12 px-2 m-0 row">
                {products.map((item, i) =>
                    <div key={item._id} className="col-12 col-sm-6 col-xl-4 stock__card row mx-0">
                        <div className="col-6 px-0 stock__card__info">
                            <p>Código de producto: <span className="montserrat">{item.productCode}</span></p>
                            <img className="payment__product-thumbnail" src={item.frontPicture.phone} alt=""/>
                            <p>PRODUCT: <span className="montserrat">{item.title} {item.type}</span></p>
                            <p>Color: <span className="montserrat">{item.color}</span></p>
                            <p>Price: <span className="montserrat">{item.price} €</span></p>
                            <p>Description: <span className="montserrat">{item.description}</span></p>
                        </div>
                        {!edit && productId === item._id &&
                        <div className="col-4 px-0 stock__card__availble">

                            <form id="updating-product-form" onSubmit={handleSubmit(onSubmit)}>
                                <p className="badge badge-pill badge-primary montserrat">EDITING STOCK</p>

                                {item.type !== 'Cap' && item.type !== 'Surfboard' && item.type !== 'Beanie' &&
                                <div>
                                    <p>S: <input name="s" type="number" defaultValue={item.availableSizes.S} ref={register({required: false})}/></p>
                                    <p>M: <input name="m" type="number" defaultValue={item.availableSizes.M} ref={register({required: false})}/></p>
                                    <p>L: <input name="l" type="number" defaultValue={item.availableSizes.L} ref={register({required: false})}/></p>
                                    <p>XL: <input name="xl" type="number" defaultValue={item.availableSizes.XL} ref={register({required: false})}/></p>
                                    <p>XXL: <input name="xxl" type="number" defaultValue={item.availableSizes.XXL} ref={register({required: false})}/></p>
                                </div>
                                }
                                <h5>PRICE: <span className="badge-warning">Sólo números sin decimales</span> <input name="price" type="number" defaultValue={item.price} ref={register({required: false})}/></h5>
                                <p>Description:
                                    <textarea className="textarea" name="description" type="text" defaultValue={item.description} ref={register({required: false})}/>
                                </p>
                                {((item.type === 'Cap') || (item.type === 'Surfboard' || (item.type === 'Beanie'))) &&
                                    <div>
                                        <p>Available quantity:
                                            <input name="uniquesize" type="number" defaultValue={item.availableSizes.uniqueSize} ref={register({required: false})}/>
                                        </p>
                                    </div>


                                }

                                <div className="stock__card__editing-buttons">
                                    <button onClick={cancelEdit} className="btn btn-danger">CANCEL</button>
                                    <input type="submit" className="btn btn-success" value="UPDATE STOCK"/>
                                </div>
                            </form>
                        </div>
                        }
                        <div className="col-2 px-0 stock__card__availble">
                            <p>AVAILABLE STOCK:</p>
                            {item.type !== 'Cap' && item.type !== 'Surfboard' && item.type !== 'Beanie' &&
                            <div>
                                <p>S: <span className="montserrat">{item.availableSizes.S}</span></p>
                                <p>M: <span className="montserrat">{item.availableSizes.M}</span></p>
                                <p>L: <span className="montserrat">{item.availableSizes.L}</span></p>
                                <p>XL: <span className="montserrat">{item.availableSizes.XL}</span></p>
                                <p>XXL: <span className="montserrat">{item.availableSizes.XXL}</span></p>
                            </div>
                            }
                            { ((item.type === 'Cap') || (item.type === 'Surfboard' || (item.type === 'Beanie'))) &&
                            <p><span
                                className="montserrat">{item.availableSizes.uniqueSize && item.availableSizes.uniqueSize}</span>
                            </p>
                            }
                            {edit &&
                            <button onClick={() => editStock(item._id)} className="btn btn-primary">EDIT</button>
                            }
                        </div>
                    </div>
                )}
            </div>

        </div>
    )
}
