import React, {useEffect} from "react";
import "./SharedLegacy.scss";
import Footer from "../Footer";

export default function ReturnsPolicyPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="legacy__container">
                <h2>RETURNS & EXCHANGES</h2>
                <div className="legacy__paragraph">
                    <p className="legacy__text">Changes and returns of an order can be made within a maximum period of 7 working
                        days after placing the order by sending an email to <span className="voodoo legacy__underline-text">contact@thevoodoobrand.com</span> or by
                        calling us at <span className="voodoo">665.80.35.31.</span></p>
                    <p className="legacy__text">To proceed with a <span className="voodoo">product change</span> you can send us the package through the post office
                        or any transport agency (according to your choice) paying the necessary postage and
                        once the package is received, if the garment is not used or washed, we will make the
                        change, making a new shipment.</p>
                    <p className="legacy__text">
                        To make a <span className="voodoo">product return</span> you can send us the package through the post office or any
                        transport agency (according to your choice) paying the necessary postage and once the
                        package is received, if the garment is not used or washed, we will refund the money.
                    </p>
                    <p className="legacy__text"><span className="voodoo">The package will be under your responsibility until it reaches our offices.</span></p>
                    <p className="legacy__text"><span className="voodoo">
                The shipping costs of an exchange or a return will be borne by The Voodoo Brand
                only in the case of an error (product in poor condition or wrong product).
            </span>
                    </p>
                </div>
            </div>
            <Footer/>
        </div>

    )
}
